import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Autocomplete, TextField, Grid, Typography, Box } from '@mui/material';
import { API_BASE_URL, IMAGE_BASE_URL } from '../../config/constants'; // Replace with your base URL

const SearchWithDropdown = ({ onSelectItem }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios.get(`${API_BASE_URL}/items`).then(response => {
      setItems(response.data || []);
    }).catch(error => {
      console.error('Error fetching items:', error);
    });
  }, []); 

  // Filter items based on the search term and handle null or empty labels
  const filteredItems = items.filter((item) => {
    const label = item.label ? item.label.toLowerCase() : ''; // Handle null, empty or undefined label
    return label.includes(searchTerm.toLowerCase());
  });

  return (
    <Box sx={{ width: '100%' }}> {/* Make the container full width */}
      <Autocomplete
        freeSolo
        fullWidth  // Make the Autocomplete full width
        options={filteredItems}
        getOptionLabel={(option) => option.label || 'No Label Available'} // Fallback for empty or null labels
        renderOption={(props, option) => (
          <Box
            component="li"
            {...props}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              padding: 1,
              borderBottom: '1px solid #ddd',
            }}
          >
            <img
              src={option.image ? `${IMAGE_BASE_URL}${option.image}` : '/path-to-placeholder.png'} // Placeholder for missing image
              alt={option.label || 'No Label Available'} // Alt text handling null/empty labels
              style={{ width: 100, objectFit: 'contain' }}  // Enlarged image size
            />
            <Box>
              <Typography>{option.label || 'No Label Available'}</Typography>
              <Typography variant="body2" color="textSecondary">
                Price: ₹{option.sellingprice || 'N/A'}
              </Typography> {/* Display price here */}
            </Box>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search items"
            variant="outlined"
            fullWidth  // Make the input field full width
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        )}
        onChange={(e, value) => {
          if (value) {
            console.log(`Selected: ${value.label || 'No Label Available'}`);
            // Call the onSelectItem function passed from the parent with the selected item
            onSelectItem(value);
          }
        }}
      />
    </Box>
  );
};

export default SearchWithDropdown;
