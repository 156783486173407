import React from "react";
import { Container } from "@mui/material";
import { FaInstagram, FaFacebook } from "react-icons/fa";

const Footer = () => {
  return (
    <Container >
      <footer style={styles.footer}>
        <div style={styles.line}></div>
        <div style={styles.iconsRow}>
          <a
            href="https://www.instagram.com/your_handle"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconLink}
          >
            <FaInstagram style={styles.icon} />
            <span>@your_handle</span>
          </a>
          <a
            href="https://www.facebook.com/your_page"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.iconLink}
          >
            <FaFacebook style={styles.icon} />
          </a>
        </div>
        <div style={styles.linksRow}>
          <a href="/terms-of-use" style={styles.link}>
            Terms of Use
          </a>
          <a href="/cancellation-refund-policy" style={styles.link}>
            Cancellation/Refund Policy
          </a>
          <a href="/contact-us" style={styles.link}>
            Contact Us
          </a>
        </div>
      </footer>
    </Container>
  );
};

const styles = {
  footer: {
    padding: "0px",
    textAlign: "center",
    backgroundColor: "#fff",
    borderTop:"1px solid #ccc",
    marginTop: "30px",
    marginBottom: "30px",
  },
  line: {
    marginBottom: "10px",
  },
  iconsRow: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    marginBottom: "10px",
  },
  iconLink: {
    display: "flex",
    alignItems: "center",
    color: "#333",
    textDecoration: "none",
    fontSize: "14px",
  },
  icon: {
    marginRight: "5px",
    fontSize: "25px",
    color:"red"
  },
  linksRow: {
    display: "flex",
    justifyContent: "center",
    gap: "20px",
  },
  link: {
    color: "#007bff",
    textDecoration: "none",
    fontSize: "14px",
  },
};

export default Footer;
