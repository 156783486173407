import React, { useState } from 'react';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Typography, Stack, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const SellingPriceCalculator = ({
    priceAdjustmentModalOrginal,
    setForm,
    form,
    handleClose,
}) => {
    const [priceAdjustmentModal, setPriceAdjustmentModal] = useState(priceAdjustmentModalOrginal);

    const handleCheckboxChange = (event) => {
        const isChecked = event.target.checked;
        const newPrice = isChecked ? priceAdjustmentModal.price + 320 : priceAdjustmentModal.price;
        const newCalculation = isChecked ? `+ 320: ${newPrice.toFixed(2)}` : `- 320: ${priceAdjustmentModal.price.toFixed(2)}`;

        setPriceAdjustmentModal((prevState) => ({
            ...prevState,
            checkboxChecked: isChecked,
            adjustedPrice: newPrice,
            calculations: [...prevState.calculations, newCalculation],
        }));
    };

    const handleAdjustment = (amount) => {
        const newPrice = priceAdjustmentModal.adjustedPrice + amount;
        const newCalculation = `${amount >= 0 ? '+' : ''} ${amount}: ${newPrice.toFixed(2)}`;

        setPriceAdjustmentModal((prevState) => ({
            ...prevState,
            adjustedPrice: newPrice,
            calculations: [...prevState.calculations, newCalculation],
        }));
    };

    const resetPriceAdjustment = () => {
        setPriceAdjustmentModal(priceAdjustmentModalOrginal);
    };

    const handlePriceAdjustmentConfirm = () => {
        setForm({ ...form, sellingprice: priceAdjustmentModal.adjustedPrice.toFixed(2) });
        handleClose();
    };

    return (
        <Dialog open onClose={handleClose} maxWidth="xs" fullWidth>
            <DialogTitle>Adjust Price</DialogTitle>
            <DialogContent>
                <Typography variant="body1">Price: ₹{priceAdjustmentModal.price}</Typography>
                <FormControlLabel
                    control={<Checkbox checked={priceAdjustmentModal.checkboxChecked} onChange={handleCheckboxChange} />}
                    label="+ 320 Adjustment"
                />

                <Divider sx={{ my: 2 }} />

                <Stack direction="row" spacing={2} justifyContent="center">
                    <IconButton color="primary" onClick={() => handleAdjustment(100)}><AddIcon />+100</IconButton>
                    <IconButton color="secondary" onClick={() => handleAdjustment(-100)}><RemoveIcon />-100</IconButton>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 1 }}>
                    <IconButton color="primary" onClick={() => handleAdjustment(50)}><AddIcon />+50</IconButton>
                    <IconButton color="secondary" onClick={() => handleAdjustment(-50)}><RemoveIcon />-50</IconButton>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="center" sx={{ mt: 1 }}>
                    <IconButton color="primary" onClick={() => handleAdjustment(25)}><AddIcon />+25</IconButton>
                    <IconButton color="secondary" onClick={() => handleAdjustment(-25)}><RemoveIcon />-25</IconButton>
                </Stack>

                <Typography variant="h6" sx={{ mt: 2 }}>Adjusted Price: ₹{priceAdjustmentModal.adjustedPrice.toFixed(2)}</Typography>

                <Typography variant="subtitle1" sx={{ mt: 1 }}>Calculations:</Typography>
                <Box component="ul" sx={{ pl: 2 }}>
                    {priceAdjustmentModal.calculations.map((calc, index) => (
                        <li key={index}>{calc}</li>
                    ))}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={resetPriceAdjustment} color="error">Reset</Button>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handlePriceAdjustmentConfirm} variant="contained" color="primary">OK</Button>
            </DialogActions>
        </Dialog>
    );
};

export default SellingPriceCalculator;
