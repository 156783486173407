import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,
  Grid,
  Pagination,
  Collapse,
  Slider,
  Button,
  Drawer,
  useMediaQuery,
  Alert,
  PaginationItem,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SalesCard from "./SalesCard";
import { API_BASE_URL, IMAGE_BASE_URL } from "../../config/constants";
import { GlobalContext } from "./../../GlobalProvider";
import TuneIcon from "@mui/icons-material/Tune";
import { subcategoryOptionsDefault } from "../../config/constantselections";
import OrderSummaryShort from "./../OrderSummary/OrderSummaryShort";
import { useTheme } from "@mui/material/styles";
import { useToast } from "./../../utility-v1/ToastProvider";
import { FONT_FAMILY } from "../../config/themeConstants";
import { ArrowCircleLeft, ArrowCircleRight } from "@mui/icons-material";

const SalesList = ({ allItems }) => {
  const { handleShowToast } = useToast();
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 16; // Items per page
  const { category } = useParams();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [priceRange, setPriceRange] = useState([0, 1500]); // Price range
  const [selectedColor, setSelectedColor] = useState(""); // Selected color
  const { globalVariable, updateGlobalVariable } = useContext(GlobalContext);
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false); // State for drawer visibility
  const [storedCart, setStoredCart] = useState([]);
  const [comboCart, setComboCart] = useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Color mapping based on subcategoryOptionsDefault
  const colorMap = {
    1: "#ff0000", // Red
    2: "#008000", // Green
    3: "#ffffff", // White
    4: "mixed", // Special case for Mixed (four colors)
  };

  // Reset pagination when category changes
  useEffect(() => {
    setCurrentPage(1); // Reset to the first page whenever the category changes
  }, [category]);

  const showAlert = () => {
    setAlertVisible(true);
    setTimeout(() => setAlertVisible(false), 3000); // Hide after 3 seconds
  };

  // Pagination logic: Calculate the items to display for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Apply category filter first
  const filteredItems =
    category === "All"
      ? allItems // Show all items if the category is "All"
      : allItems.filter((item) => item.category === category);

  // Apply additional filters: price range and selected color
  const furtherFilteredItems = filteredItems.filter((item) => {
    const sellingPrice = parseFloat(item.sellingprice);
    const matchesPrice =
      sellingPrice >= priceRange[0] && sellingPrice <= priceRange[1];
    const matchesColor =
      selectedColor === "" || item.subcategory === selectedColor;

    return matchesPrice && matchesColor;
  });

  // Paginate the filtered items
  const filteredAndPaginatedItems = furtherFilteredItems.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const addToCart = (inventoryid, itemDifference) => {
    const item = filteredItems.find((item) => item.inventoryid === inventoryid);

    if (item) {
      const storedCart = JSON.parse(localStorage.getItem("cartItems")) || {};

      // Check the current quantity in the cart for this item
      const currentQuantity = storedCart[item.inventoryid]?.quantity || 0;

      if (currentQuantity + 1 > itemDifference) {
        handleShowToast("Item exceeded stock quantity", "warning");
        return; // Prevent further execution
      }

      // Add or update the item in the cart
      if (storedCart[item.inventoryid]) {
        storedCart[item.inventoryid].quantity += 1;
      } else {
        storedCart[item.inventoryid] = {
          image: item.image,
          label: item.label,
          price: item.sellingprice,
          quantity: 1,
          left: 0,
        };
      }

      // Update localStorage and global state
      localStorage.setItem("cartItems", JSON.stringify(storedCart));
      updateGlobalVariable("cartItems", storedCart);

      // Update state
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || {};
      setStoredCart(cartItems);

      // Check and assign empty object if "comboCart" is null
      const comboCartItems =
        JSON.parse(localStorage.getItem("comboCart")) || {};
      setComboCart(comboCartItems);

      // Open the drawer
      handleShowToast("Item added to cart", "success");
    }
  };

  const toggleFilterSection = () => {
    setIsFilterOpen((prev) => !prev);
  };

  const viewProductDetails = (id) => {
    navigate(`/list/${category}/${id}`);
  };

  // Handle price range change and reset pagination to the first page
  const handlePriceRangeChange = (event, newValue) => {
    setPriceRange(newValue);
    setCurrentPage(1); // Reset to the first page when filter is applied
  };

  // Handle color selection
  const handleColorSelect = (colorValue) => {
    setSelectedColor(colorValue);
    setCurrentPage(1); // Reset to the first page when color is selected
  };

  const resetFilters = () => {
    setPriceRange([0, 1500]);
    setSelectedColor("");
    setCurrentPage(1); // Reset pagination to the first page
    setIsFilterOpen(false); // Close the filter section
  };

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        sx={{
          height: { xs: "40px", md: "60px" },
          marginBottom: { xs: "20px", md: "15px" },
        }}
      >
        <Grid item xs={2} md={2}>
          <Button
            onClick={toggleFilterSection}
            sx={{
              fontFamily: FONT_FAMILY,
              textTransform: "none",
              color: "#1976d2",
            }}
          >
            <Box
              sx={{
                display: { xs: "inline", sm: "inline" }, // Hide text on mobile, show on desktop
              }}
            >
              <TuneIcon
                sx={{
                  fontSize: { xs: "28px", lg: "30px" },
                  marginLeft: { xs: "-40px" },
                  marginTop: { xs: "10px", md: "20px" },
                }}
              />
            </Box>
          </Button>
        </Grid>

        <Grid
          item
          xs={10}
          md={10}
          sx={{
            display: "flex", // Use flexbox for alignment
            justifyContent: "flex-end", // Aligns the content to the right
          }}
        >
          <Pagination
            count={Math.ceil(furtherFilteredItems.length / itemsPerPage)} // Total number of pages
            page={currentPage} // Current page
            onChange={handlePageChange} // Function to handle page changes
            siblingCount={1} // Number of pages to show on each side of the current page
            boundaryCount={1} // Number of pages to show at the start and end
            renderItem={(item) => (
              <PaginationItem
                {...item}
                components={{
                  previous: ArrowCircleLeft,
                  next: ArrowCircleRight,
                }}
                sx={{
                  fontSize:
                    item.type === "previous" || item.type === "next"
                      ? "30px"
                      : "20px",
                  "& svg": {
                    fontSize: "30px", // Adjust icon size for arrows
                  },
                  color:
                    item.type === "previous" || item.type === "next"
                      ? item.disabled
                        ? "gray"
                        : "teal"
                      : item.selected
                      ? "red"
                      : "black",
                  "@media (hover: hover)": {
                    "&:hover": {
                      color:
                        item.type === "previous" || item.type === "next"
                          ? item.disabled
                            ? "gray"
                            : "darkblue"
                          : "darkred",
                    },
                  },
                }}
              />
            )}
            sx={{
              maxWidth: "300px", // Limit width
              "& .MuiPaginationItem-root": {
                fontSize: { xs: "20px", lg: "22px" },
                minWidth: "25px",
                height: "25px",
                margin: "0 3px",
              },
              "& .Mui-selected": {
                color: "red", // Selected number color
                fontWeight: "bold",
                backgroundColor: "transparent",
              },
            }}
          />
        </Grid>
      </Grid>
      {/* Pagination and Tune Icon */}

      {/* Filter Section */}
      <Grid>
        <Collapse in={isFilterOpen}>
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "12px", // Round edges for the filter section
              border: "1px solid #ccc", // Border for the entire box
              p: 2,
              mb: 2,
              position: "relative",
              width: "100%",
            }}
          >
            {/* "Filter By" styled as a title on the border line */}
            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: "-12px",
                left: "16px",
                backgroundColor: "#fff",
                color: "rgba(0, 0, 0, 0.6)",
                fontWeight: 500,
                fontSize: "0.85rem",
                borderRadius: "12px",
              }}
            >
              Filter by price:
            </Typography>

            {/* Reset Filters as a plain hyperlink on the top-right */}
            <Box
              sx={{
                position: "absolute",
                top: "-12px",
                right: "16px",
                backgroundColor: "#fff",
                fontSize: "0.85rem",
              }}
            >
              <Button
                onClick={resetFilters}
                sx={{
                  textTransform: "none", // Remove uppercase styling
                  color: "#1976d2", // Use a link-like color
                  fontWeight: 500,
                  fontSize: "0.85rem",
                  padding: 0,
                  minWidth: "auto",
                }}
              >
                Reset Filters
              </Button>
            </Box>

            {/* Price Range Slider */}
            <Box
              sx={{
                width: "100%",
                marginTop: 2,
                paddingLeft: 3,
                paddingRight: 3,
                paddingTop: 2,
              }}
            >
              <Slider
                value={priceRange}
                onChange={handlePriceRangeChange}
                min={0}
                max={1500}
                step={10}
                marks={[
                  { value: 0, label: "₹0" },
                  { value: 500, label: "₹500" },
                  { value: 1000, label: "₹1000" },
                  { value: 1500, label: "₹1500" },
                ]}
                valueLabelDisplay="on" // Always show the tooltip
                valueLabelFormat={(value) => `₹${value}`} // Format value to show Rs
              />
              {false && (
                <Grid container spacing={3}>
                  {subcategoryOptionsDefault
                    .filter(
                      (option) => option.value !== "-1" && option.value !== "0"
                    )
                    .map((option) => (
                      <Grid item key={option.value}>
                        <Button
                          onClick={() => handleColorSelect(option.value)}
                          sx={{
                            backgroundImage:
                              option.value === "4"
                                ? "conic-gradient(red 0 25%, green 25% 50%, blue 50% 75%, gold 75% 100%)"
                                : "none",
                            backgroundColor:
                              option.value === "4"
                                ? "transparent"
                                : colorMap[option.value],
                            border:
                              selectedColor === option.value
                                ? "2px solid darkblue" // Dark blue for selected color
                                : "1px solid lightgray", // Light gray for unselected colors
                            width: selectedColor === option.value ? 50 : 40, // Larger size for selected color
                            height: selectedColor === option.value ? 50 : 40, // Larger size for selected color
                            borderRadius: "50%",
                            minWidth: 0,
                            padding: 0,
                            "&:hover": {
                              opacity: 0.8,
                            },
                          }}
                        />
                      </Grid>
                    ))}
                </Grid>
              )}
            </Box>
          </Box>
        </Collapse>
      </Grid>

      {/* Items List */}
      <Grid container spacing={2}>
        {filteredAndPaginatedItems.map((item) => (
          <Grid item xs={6} md={3} key={item.inventoryid}>
            <SalesCard
              title={item.label}
              image={item.image}
              discount={item.discount}
              sellingprice={item.sellingprice}
              itemleft={item.difference}
              originalprice={
                parseFloat(item.sellingprice) +
                (parseFloat(item.sellingprice) + parseInt(item.discount) / 100)
              }
              addToCart={() => addToCart(item.inventoryid, item.difference)}
              viewProductDetails={() => viewProductDetails(item.inventoryid)}
            />
          </Grid>
        ))}
      </Grid>

      {/* Pagination on bottom */}
      <Grid container justifyContent="center" mt={2}>
        <Pagination
          count={Math.ceil(furtherFilteredItems.length / itemsPerPage)} // Total number of pages
          page={currentPage} // Current page
          onChange={handlePageChange} // Function to handle page changes
          siblingCount={1} // Number of pages to show on each side of the current page
          boundaryCount={1} // Number of pages to show at the start and end
          renderItem={(item) => (
            <PaginationItem
              {...item}
              components={{
                previous: ArrowCircleLeft,
                next: ArrowCircleRight,
              }}
              sx={{
                fontSize:
                  item.type === "previous" || item.type === "next"
                    ? "30px"
                    : "20px",
                "& svg": {
                  fontSize: "30px", // Adjust icon size for arrows
                },
                color:
                  item.type === "previous" || item.type === "next"
                    ? item.disabled
                      ? "gray"
                      : "teal"
                    : item.selected
                    ? "red"
                    : "black",
                "@media (hover: hover)": {
                  "&:hover": {
                    color:
                      item.type === "previous" || item.type === "next"
                        ? item.disabled
                          ? "gray"
                          : "darkblue"
                        : "darkred",
                  },
                },
              }}
            />
          )}
          sx={{
            maxWidth: "300px", // Limit width
            "& .MuiPaginationItem-root": {
              fontSize: { xs: "20px", lg: "22px" },
              minWidth: "25px",
              height: "25px",
              margin: "0 3px",
            },
            "& .Mui-selected": {
              color: "red", // Selected number color
              fontWeight: "bold",
              backgroundColor: "transparent",
            },
          }}
        />
      </Grid>
      {/* Drawer for showing OrderSummaryShort */}
      <Drawer
        anchor={isSmallScreen ? "top" : "right"} // Top for small, right for large
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Box
          sx={{
            p: 2,
            width: isSmallScreen ? "100%" : "400px", // Full width for top, fixed width for right
            maxWidth: "lg",
          }}
        >
          <OrderSummaryShort
            cartItems={storedCart}
            comboCartObject={comboCart}
          />
          <Button onClick={() => setDrawerOpen(false)} sx={{ mt: 2 }}>
            Close
          </Button>
        </Box>
      </Drawer>
    </Box>
  );
};

export default SalesList;
