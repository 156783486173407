import React from "react";
import { Grid, Typography } from "@mui/material";
import { FONT_FAMILY } from "../../config/themeConstants";

const HeaderGrid = ({ pathArray, content, additionalContent }) => {
  // Define the header text based on the path array conditions
  const HEADER_TEXT = (() => {
    if (pathArray[0] === "list") {
      if (pathArray[1] === "All") {
        return "All Items";
      } else if (pathArray[1] === "Necklace") {
        return "Necklace Collection";
      } else if (pathArray[1] === "BB") {
        return "Bracelet and Bangles";
      } else if (pathArray[1] === "Haarams") {
        return "Haarams Collection";
      } else if (pathArray[1] === "Earrings") {
        return "Earrings Collection";
      } else if (pathArray[1] === "Adstone") {
        return "Adstone Collection";
      }
    } else if (pathArray[0] === "combooffer") {
      return "Combo Offers";
    } else if (pathArray[0] === "bag") {
      return "Items in your cart";
    }

    // Add more conditions as needed
    return "Our Products";
  })();

  return (
    <Grid
      container
      alignItems="center"

      sx={{
        height: { xs: "50px", md: "60px" },
      }}
    >
      <Grid
        item
        xs={7}
        md={7}
        lg={6}
      >
        <Typography
          sx={{
            fontFamily: FONT_FAMILY,
            color: "#000",
            fontSize: { xs: "20px", lg: "24px" },
            fontWeight: 500,
          }}
        >
          {HEADER_TEXT}
        </Typography>
      </Grid>
      <Grid item xs={3} md={4} lg={4}>
        <Typography>{content}</Typography>
      </Grid>
      <Grid item xs={2} md={1} lg={2}>
        {additionalContent}
      </Grid>
    </Grid>
  );
};

export default HeaderGrid;
