import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Box, Grid, Typography, Card, CardMedia, CardContent, Badge , CardActions, Link, IconButton, Button} from '@mui/material';
import ShipmentCard from './../ShipmentCard/ShipmentCard';
import SalesCardwithCarousel from '../../components/SalesCardwithCarousel/SalesCardwithCarousel'; 
import { API_BASE_URL } from '../../config/constants';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import ModalBox from './../../utility-v1/ModalBox';
import {
    indianStates
  } from "../../config/constantselections";
dayjs.extend(localizedFormat);

const ShipmentPage = () => {
    const [pendingSales, setPendingSales] = useState([]);
    const [completedSales, setCompletedSales] = useState([]);
    const [items, setItems] = useState([]);
    const [shipmentDetails, setShipmentDetails] = useState({});
    const [selectedShipments, setSelectedShipments] = useState([]);
    const [selectedSale, setSelectedSale] = useState(null);
    const [modalConfig, setModalConfig] = useState({ visible: false, action: null, title: '', content: '', id: null });
    const [isEditing, setIsEditing] = useState(false);
    const [openItemsDetailsModal, setOpenItemsDetailsModal] = useState(false);
    const handleOpen = () => setOpenItemsDetailsModal(true);
    const handleClose = () => setOpenItemsDetailsModal(false);
    
      const getStateLabel = (value) => {
        const state = indianStates.find((state) => state.value === value);
        return state ? state.label : "";
      };

    useEffect(() => {
        axios.get(`${API_BASE_URL}/salespending`)
            .then(response => {
                setPendingSales(response.data);
                initializeShipmentDetails(response.data);
            })
            .catch(error => console.error('Error fetching pending sales:', error));

        axios.get(`${API_BASE_URL}/salescomplete`)
            .then(response => setCompletedSales(response.data))
            .catch(error => console.error('Error fetching completed sales:', error));

        axios.get(`${API_BASE_URL}/items`)
            .then(response => setItems(response.data || []))
            .catch(error => console.error('Error fetching items:', error));
    }, []);

    const initializeShipmentDetails = (salesData) => {
        const initialDetails = {};
        salesData.forEach(sale => {
            if (!shipmentDetails[sale.id]) {
                initialDetails[sale.id] = {
                    shipmentDate: dayjs(sale.shipmentDate).isValid()
                        ? dayjs(sale.shipmentDate).format('YYYY-MM-DD')
                        : dayjs().format('YYYY-MM-DD'),
                    shipmentPrice: sale.shipmentPrice || '',
                    shipmentMethod: sale.shipmentMethod || '',
                    trackingId: sale.trackingId || ''
                };
            }
        });
        setShipmentDetails(initialDetails);
    };

    const handleShipmentChange = (e, id) => {
        const { name, value } = e.target;
        setShipmentDetails(prevDetails => ({
            ...prevDetails,
            [id]: {
                ...prevDetails[id],
                [name]: value
            }
        }));
    };

    const handleCheckboxChange = (id) => {
        setSelectedShipments(prevSelected =>
            prevSelected.includes(id)
                ? prevSelected.filter(saleId => saleId !== id)
                : [...prevSelected, id]
        );
    };

    const handleGenerateLabel = () => {
        const selectedDetails = pendingSales.filter(sale => selectedShipments.includes(sale.id));
    
        if (selectedDetails.length === 0) {
            alert("Please select at least one shipment to generate a label.");
            return;
        }
    
        const printWindow = window.open('', '', 'height=600,width=800');
        printWindow.document.write('<html><head><title>Shipment Labels</title><style>');
        printWindow.document.write(`
            body {
                font-family: Arial, sans-serif;
                margin: 20px;
                font-size: 18px;
            }
            .label-container {
                display: grid;
                grid-template-columns: repeat(2, 50%);
                column-gap: 20px;
                row-gap: 20px;
                page-break-inside: avoid;
            }
            .label {
                border: 1px solid #000;
                padding: 10px;
                box-sizing: border-box;
                page-break-inside: avoid;
            }
            .from-address {
                font-size: 13px;
                text-align: left;
                margin-bottom: 10px;
                white-space: pre-line;
            }
            .to-address {
                font-size: 24px;
                text-align: left;
                margin-bottom: 10px;
                white-space: pre-line;
                margin-left: 50px;
            }
            .address-line {
                margin: 0;
                padding: 0;
            }
            .label-title {
                font-size: 20px;
                font-weight: bold;
            }
        `);
        printWindow.document.write('</style></head><body>');
        printWindow.document.write('<div class="label-container">');
    
        selectedDetails.forEach(sale => {
            printWindow.document.write('<div class="label">');
    
            // FROM Address
            printWindow.document.write('<div class="from-address">');
            printWindow.document.write('<p class="label-title">FROM:</p>');
            printWindow.document.write('<p class="address-line">ANJANA ASOK</p>');
            printWindow.document.write('<p class="address-line">KERA 135, HARISMRITHI LANE,</p>');
            printWindow.document.write('<p class="address-line">KANJIRAMPARA, TRIVANDRUM,</p>');
            printWindow.document.write('<p class="address-line">KERALA, PIN 695030</p>');
            printWindow.document.write('<p class="address-line">PH: 7907448913</p>');
            printWindow.document.write('</div>');
    
            // TO Address (Buyer Details)
            printWindow.document.write('<div class="to-address">');
            printWindow.document.write('<p class="label-title">TO:</p>');
            printWindow.document.write(`<p class="address-line"><strong>${sale.name}</strong></p>`);
            printWindow.document.write(`<p class="address-line">${sale.buyerDetails.replace(/\n/g, '<br/>')}</p>`);
            printWindow.document.write(`<p class="address-line">Pin Code: ${sale.pincode}</p>`);
            printWindow.document.write(`<p class="address-line">${getStateLabel(sale.state)}</p>`);
            
            printWindow.document.write(`<p class="address-line">PH: ${sale.phoneNumber}</p>`);
            printWindow.document.write('</div>');
    
            printWindow.document.write('</div>');
        });
    
        printWindow.document.write('</div>');
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };
    
    const handleSubmit = (id) => {
        const shipmentData = shipmentDetails[id];
    
        // Check if shipment data exists for the given sale
        if (!shipmentData || !shipmentData.shipmentDate || !shipmentData.shipmentPrice) {
            alert('Please fill in all required shipment details.');
            return;
        }
    
        // Validate shipment date format using dayjs
        if (!dayjs(shipmentData.shipmentDate, 'YYYY-MM-DD', true).isValid()) {
            alert('Invalid shipment date format. Please use YYYY-MM-DD.');
            return;
        }
    
        // Update shipment data in the backend
        axios.put(`${API_BASE_URL}/sales/${id}/shipment`, shipmentData)
            .then(response => {
                // After successful submission, move the sale to completed sales
                setPendingSales(prevSales => prevSales.filter(sale => sale.id !== id));
                setCompletedSales(prevSales => [
                    ...prevSales,
                    {
                        ...pendingSales.find(sale => sale.id === id),
                        ...shipmentData,
                        id,
                        salesStatus: 'SD' // Set status to "Shipped"
                    }
                ]);
    
                // Optionally, clear the form or reset the shipment details
                const updatedShipmentDetails = { ...shipmentDetails };
                delete updatedShipmentDetails[id];
                setShipmentDetails(updatedShipmentDetails);
    
                alert('Shipment details submitted successfully!');
            })
            .catch(error => {
                console.error('Error submitting shipment details:', error);
                alert('Error submitting shipment details. Please try again.');
            });
    };
    
    const handleRevert = (id) => {
        const saleToRevert = completedSales.find(sale => sale.id === id);
    
        if (!saleToRevert) {
            alert("Sale not found.");
            return;
        }
    
        // Send a PUT request to revert the sale's status to "Pending"
        axios.put(`${API_BASE_URL}/sales/${id}/status`, { salesStatus: 'SP' }) // 'SP' for 'Sale Pending'
            .then(() => {
                // Remove from completed sales and move back to pending sales
                setCompletedSales(prevSales => prevSales.filter(sale => sale.id !== id));
    
                setPendingSales(prevSales => [
                    ...prevSales,
                    {
                        ...saleToRevert,
                        salesStatus: 'SP' // Mark the status as 'Pending'
                    }
                ]);
    
                // Update the shipment details to reflect the reverted sale if needed
                setShipmentDetails(prevDetails => ({
                    ...prevDetails,
                    [id]: {
                        shipmentDate: saleToRevert.shipmentDate || '',
                        shipmentPrice: saleToRevert.shipmentPrice || '',
                        shipmentMethod: saleToRevert.shipmentMethod || '',
                        trackingId: saleToRevert.trackingId || ''
                    }
                }));
    
                alert('Sale has been successfully reverted to pending status.');
            })
            .catch(error => {
                console.error('Error reverting sale status:', error);
                alert('Error reverting sale status. Please try again.');
            });
    };
    

    const handleViewClick = (e, sale) => {
        e.preventDefault(); 
        setSelectedSale(sale);
        handleOpen();
    };
    const handleCloseModal = () => {
        setSelectedSale(null);
    };

    return (
        <div>
            {/* Pending Sales List */}
            <Typography variant="h5" gutterBottom>
                Shipment List
            </Typography>
            <Grid container spacing={2}>
                {pendingSales.map((sale, index) => (
                    <Grid item xs={12} key={sale.id}>
                        <ShipmentCard
                            key={sale.id}
                            sale={sale}
                            items={items}
                            shipmentDetails={shipmentDetails}
                            handleShipmentChange={handleShipmentChange}
                            handleSubmit={handleSubmit}
                            handleRevert={handleRevert}
                            isEditing={isEditing}
                            handleCheckboxChange={handleCheckboxChange}
                            isChecked={selectedShipments.includes(sale.id)}
                            index={index}
                        />
                    </Grid>
                ))}
            </Grid>

            {/* Generate Label Button */}
            <Grid container justifyContent="flex-end" spacing={2} style={{ marginTop: '20px' }}>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={handleGenerateLabel}>
                        Generate Label
                    </Button>
                </Grid>
            </Grid>

            {/* Recently Shipped Items */}
            <Typography variant="h5" gutterBottom style={{ marginTop: '40px' }}>
                Recently Shipped Items
            </Typography>
            <Grid container spacing={2}>
                {completedSales.map((sale, index) => (
                    <Grid item xs={12} key={sale.id}>
                        <Card variant="outlined">
                            <CardContent>
                                <Typography variant="body1">
                                    <strong>{index + 1}. {sale.name}</strong>: {sale.shipmentPrice}₹
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button href="#" onClick={(e) => handleViewClick(e, sale)}>
                                    View
                                </Button>
                                <Button href="#" onClick={() => handleRevert(sale.id)} color="primary">
                                    Revert
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {selectedSale && (<ModalBox open={openItemsDetailsModal} handleClose={handleClose} direction="up">
                        <SalesCardwithCarousel
                            items={selectedSale.items}
                            itemsList={items}
                            name={selectedSale.name}
                            buyerdetails={selectedSale.buyerDetails}
                            totalprice={selectedSale.price}
                            phonenumber={selectedSale.phoneNumber}
                        />
       </ModalBox>)}
        </div>
    );
};

export default ShipmentPage;
