import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Link,
  Skeleton,
  Stack,
} from "@mui/material";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import TextInput from "./../../utility-v1/TextInput";
import SelectBox from "./../../utility-v1/SelectBox";
import CustomButton from "./../../utility-v1/Button";
import OrderSummary from "./../SalesList/OrderSummary";
import DeliveryDetails from "./../SalesList/DeliveryDetails";
import ModalBox from "./../../utility-v1/ModalBox";
import SalesCardwithCarousel from "../../components/SalesCardwithCarousel/SalesCardwithCarousel";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import ItemSelector from "../ItemSelector/ItemSelector";
import { API_BASE_URL, IMAGE_BASE_URL } from "../../config/constants";
import { GlobalContext } from "./../../GlobalProvider";
import CustomDrawer from "./../../utility-v1/CustomDrawer";
import SearchWithDropdown from "./SearchWithDropdown";
import ComboOffer from "./../ComboOffer/ComboOffer";

import SalesTable from "./SalesTable";

// Extend dayjs with the localizedFormat plugin
dayjs.extend(localizedFormat);

const indianStates = [
  { label: "Andhra Pradesh", value: "AP" },
  // ...other states
];

export default function SalesRecord() {
  const [sales, setSales] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedSale, setSelectedSale] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [actionMode, setActionMode] = useState("ADD");
  const { globalVariable } = useContext(GlobalContext);
  const [storedCart, setStoredCart] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isEditVisible, setIsEditVisible] = useState(true); // State for Confirm visibility
  const [isPreviewVisible, setIsPreviewVisible] = useState(false); // State for Preview visibility
  const [isConfirmVisible, setIsConfirmVisible] = useState(false); // State for Confirm visibility
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const [comboCart, setComboCart] = useState({});
  const [combos, setCombos] = useState([]);
  const [isComboDrawerOpen, setIsComboDrawerOpen] = useState(false);
  const [autoDiscounts, setAutoDiscounts] = useState({
    itemCountDiscount: false,
    totalPriceDiscount: false,
  });
  const [extraDiscount, setExtraDiscount] = useState(0);
  const [extraDiscountDescription, setExtraDiscountDescription] = useState(0);
  const [form, setForm] = useState({
    items: {},
    name: "",
    salesDate: dayjs(),
    price: "",
    buyerDetails: "",
    phoneNumber: "",
    pincode: "",
    state: "KL",
    coupon: "",
    salesStatus: "SP",
    systemDate: dayjs(),
    giveAway: false,
    extraDiscount: "",
    additionalDiscount: [],
    shipping: "",
    email: "",
    extraDiscountDescription: "",
  });
  const [openItemsDetailsModal, setOpenItemsDetailsModal] = useState(false);

  const handleExtraDiscountChange = (value) => {
    setExtraDiscount(value);
  };

  const handleExtraDiscountDescriptionChange = (value) => {
    console.error("Failed handleExtraDiscountDescriptionChange", value);
    setExtraDiscountDescription(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchSales();
        const itemsResponse = await axios.get(`${API_BASE_URL}/items`);
        setItems(itemsResponse.data || []);

        const response = await axios.get(`${API_BASE_URL}/combo`);
        setCombos(response.data || []);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };

    fetchData();
  }, []);

  const fetchSales = async () => {
    try {
      const salesResponse = await axios.get(`${API_BASE_URL}/salespending`);
      setSales(salesResponse.data || []);
    } catch (error) {
      console.error("Failed to fetch sales data", error);
    }
  };
  const toggleComboDrawer = (open) => () => {
    setIsComboDrawerOpen(open);
  };
  const handleComboSelect = (combo) => {
    console.log("Selected Combo:", combo);

    // Retrieve `comboId` as the key for each combo
    const comboKey = Object.keys(combo)[0];
    if (!comboKey) {
      console.error("Combo object is missing `comboId`.");
      return;
    }

    // Update `comboCart` by appending the new combo
    setComboCart((prevComboCart) => ({
      ...prevComboCart,
      [comboKey]: combo[comboKey],
    }));

    setIsComboDrawerOpen(false); // Close Drawer after selection
  };

  const resetForm = () => {
    setForm({
      items: {},
      name: "",
      salesDate: dayjs().format("YYYY-MM-DD"),
      price: "",
      buyerDetails: "",
      phoneNumber: "",
      salesStatus: "SP",
      systemDate: "",
      giveAway: false,
      pincode: "",
      state: "KL",
      coupon: "",
      extraDiscount: "",
      shipping: "",
      email: "",
      extraDiscountDescription: "",
    });
    setEditId(null);
    setStoredCart({});
  };

  const resetMode = () => {
    setIsPreviewVisible(false);
    setIsConfirmVisible(false);
    setIsEditVisible(true);
    setIsEditing(false);
    setActionMode("ADD");
  };

  const [errors, setErrors] = useState({
    name: false,
    buyerDetails: false,
    pincode: false,
    state: false,
    phoneNumber: false,
  });
  const validateForm = () => {
    const newErrors = {
      name: form.name === "",
      buyerDetails: form.buyerDetails === "",
      pincode: form.pincode === "",
      state: form.state === "",
      phoneNumber: form.phoneNumber === "",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).includes(true); // Returns true if no errors
  };

  // Clear validation error when the user starts typing
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setForm((prevForm) => ({
      ...prevForm,
      [name]: type === "checkbox" ? checked : value,
    }));

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false, // Clear individual validation error when typing starts
      }));
    }
  };
  // Function to map sale items with corresponding images from the `items` state
  const getSaleItemsWithImages = (saleItems, allItems) => {
    const tempStoredCart = {};
    const tempCombo = {};

    Object.keys(saleItems).forEach((itemId) => {
      if (saleItems[itemId][2] === 1) {
        // Check if the item is a combo item
        const matchedItem = combos.find((item) => item.id == itemId);
        if (matchedItem) {
          // Call addComboObjectsEdit and store the result in tempCombo
          tempCombo[itemId] = {
            comboId: matchedItem.id,
            comboname: matchedItem.comboname,
            price: matchedItem.price,
            items: addComboObjectsEdit(matchedItem.items), // Call the function and store returned array
          };
        }
      } else {
        // Handle regular items
        const matchedItem = allItems.find(
          (item) => item.inventoryid === itemId
        );
        if (matchedItem) {
          tempStoredCart[itemId] = {
            image: matchedItem.image,
            label: matchedItem.label,
            price: saleItems[itemId][1], // Price from saleItems
            quantity: saleItems[itemId][0], // Quantity from saleItems
          };
        }
      }
    });

    setStoredCart(tempStoredCart);
    console.log("setComboCart", tempCombo);
    setComboCart(tempCombo);
  };

  const addComboObjectsEdit = (inventories) => {
    const tempComboCartArray = inventories
      .map((inventoryid) => {
        const item = items.find((item) => item.inventoryid === inventoryid);
        if (item) {
          return {
            inventoryid: item.inventoryid,
            image: item.image,
            label: item.label,
            price: item.sellingprice,
            quantity: 1,
            discount: item.discount, // Add discount if needed
            sellingprice: item.sellingprice,
          };
        }
        return null;
      })
      .filter(Boolean); // Remove null values for unmatched items

    return tempComboCartArray;
  };

  const setItemsInForm = (itemsObject) => {
    setForm((prevForm) => ({
      ...prevForm,
      items: itemsObject,
    }));
    //  setStoredCart(itemsObject);
  };

  const setStoredCartItems = (itemsObject) => {
    setStoredCart(itemsObject);
  };

  const setCouponInForm = (coupon) => {
    setForm({ ...form, coupon: coupon });
  };

  const setCartPriceInForm = (itemsObject) => {
    setForm((form) => ({
      ...form,
      price: itemsObject,
    }));
  };

  const handleConfirm = () => {
    const systemDate = dayjs().format("YYYY-MM-DD");
    const salesDate = dayjs().format("YYYY-MM-DD");
    const shipmentDate = form.shipmentDate
      ? form.shipmentDate.format("YYYY-MM-DD")
      : null;

    // Prepare the sales data for the /sales endpoint

    form.additionalDiscount = [];
    autoDiscounts.totalPriceDiscount &&
      form.additionalDiscount.push("TOTALPRICE");
    autoDiscounts.itemCountDiscount &&
      form.additionalDiscount.push("ITEMCOUNT");
    const newSale = {
      ...form,
      salesDate,
      systemDate,
      shipmentDate,
      salesStatus: "SP",
      extraDiscount: extraDiscount,
      extraDiscountDescription: extraDiscountDescription,
      additionalDiscount: form.additionalDiscount,
      shipping: form.state === "KL" ? 0 : 50, // Set shipping to 50 if state is KL, otherwise 0
    };

    console.log(
      "Updated Extra form.additionalDiscount-1:",
      form.additionalDiscount
    );

    // Post the sales data
    axios
      .post(`${API_BASE_URL}/sales`, newSale)
      .then((response) => {
        const salesId = response.data.id; // Assuming the response contains the newly created sales ID

        // Prepare the payload for the /salesrecord/insert endpoint
        const salesRecordData = {
          id: salesId, // The newly created sales ID
          items: form.items, // Items from the form
        };

        // Insert the sales records for each item in the form
        return axios.post(
          `${API_BASE_URL}/salesrecord/insert`,
          salesRecordData
        );
      })
      .then(() => {
        // After inserting the records, fetch the sales data and reset the form
        fetchSales();
        resetForm();
      })
      .catch((error) => {
        console.error("Error adding sale or inserting sales record:", error);
      });
  };

  const handleViewClick = (e, sale) => {
    e.preventDefault();
    setSelectedSale(sale);
    setOpenItemsDetailsModal(true);
  };

  const handleEdit = (id) => {
    const saleToEdit = sales.find((sale) => sale.id === id);
    getSaleItemsWithImages(saleToEdit.items, items); // Map sale items to their corresponding images
    // Set the updated cart with images in the state
    setForm({
      ...saleToEdit,
      salesDate: dayjs().format("YYYY-MM-DD"),
      systemDate: dayjs().format("YYYY-MM-DD"),
      items: saleToEdit.items || {},
      price: saleToEdit.price,
      pincode: saleToEdit.pincode,
      state: saleToEdit.state,
      coupon: saleToEdit.coupon,
      extraDiscount: saleToEdit.extraDiscount,
      extraDiscountDescription: saleToEdit.extraDiscountDescription,
      email: saleToEdit.email
    });

    setExtraDiscount(saleToEdit.extraDiscount);
    setExtraDiscountDescription(saleToEdit.extraDiscountDescription);

    setIsPreviewVisible(false);
    setIsConfirmVisible(false);
    setIsEditVisible(true);

    setIsEditing(true);
    setEditId(id);
    window.scrollTo(0, 0);
  };

  const handleDelete = () => {
    // First, delete the sale itself
    axios
      .delete(`${API_BASE_URL}/sales/${editId}`)
      .then(() => {
        // After successfully deleting the sale, delete all item sales records associated with the sale id (editId)
        axios
          .delete(`${API_BASE_URL}/itemsalesrecord/salesid/${editId}`)
          .then(() => {
            fetchSales(); // Refresh the sales list
            resetForm(); // Reset the form after deletion
          })
          .catch((error) => {
            console.error("Error deleting item sales records:", error);
          });
      })
      .catch((error) => {
        console.error("Error deleting sale:", error);
      });
  };

  const handleUpdate = () => {
    const salesDate = dayjs().format("YYYY-MM-DD");
    const updatedSale = {
      ...form,
      salesDate,
      id: editId,
      salesStatus: "SP",
      extraDiscount: extraDiscount,
      extraDiscountDescription: extraDiscountDescription,
    };

    axios
      .put(`${API_BASE_URL}/sales/${editId}`, updatedSale)
      .then(() => {
        // After updating the sale, delete existing sales records for the sale id
        return axios.delete(
          `${API_BASE_URL}/itemsalesrecord/salesid/${editId}`
        );
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          // No records found to delete, proceed with insert
          console.warn("No records found to delete, proceeding with insert.");
        } else {
          // Log other types of errors
          console.error("Error deleting item sales records:", error);
          throw error; // Re-throw the error if it's not the "no records" case
        }
      })
      .then(() => {
        // Prepare the payload for inserting the new sales records
        const salesRecordData = {
          id: editId, // Use the existing editId for the updated sale
          items: form.items, // Items from the form
        };

        // Insert the new sales records for each item
        return axios.post(
          `${API_BASE_URL}/salesrecord/insert`,
          salesRecordData
        );
      })
      .then(() => {
        // After inserting the new records, fetch the sales list and reset the form
        fetchSales();
        resetForm();
      })
      .catch((error) => {
        console.error("Error adding sale or inserting sales records:", error);
      });
  };

  const addToCart = (inventoryid) => {
    const item = items.find((item) => item.inventoryid === inventoryid);

    if (item) {
      const updatedCart = { ...storedCart };

      if (updatedCart[item.inventoryid]) {
        updatedCart[item.inventoryid] = {
          ...updatedCart[item.inventoryid],
          quantity: updatedCart[item.inventoryid].quantity + 1,
        };
      } else {
        updatedCart[item.inventoryid] = {
          image: item.image,
          label: item.label,
          price: item.sellingprice,
          quantity: 1,
          boxno: item.boxno,
        };
      }

      setStoredCart(updatedCart);
      setErrorMessage(""); // Clear cart error when an item is added
      setIsEditVisible(true);
      setIsDrawerOpen(false);
    }
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };
  const renderDrawerContent = () => (
    <div>
      <ItemSelector
        items={items}
        selectedItems={Object.keys(form.items)}
        onSelectItem={(item) => addToCart(item.inventoryid)}
      />
    </div>
  );

  const renderComboDrawerContent = () => (
    <div>
      <ComboOffer callingPage="Admin" onSelectCombo={handleComboSelect} />
    </div>
  );

  const handleSaveSales = (mode) => {
    let hasError = false;

    // Check if the cart is empty
    if (isStoredCartEmpty(storedCart) && isComboCartEmpty(comboCart)) {
      setErrorMessage("Cart is empty. Please add items before saving.");
      hasError = true;
    } else {
      setErrorMessage(""); // Reset error message if cart is not empty
    }

    // Validate the form fields
    if (!validateForm()) {
      hasError = true;
    }

    // If there are any errors, don't proceed further
    if (hasError) {
      return;
    }

    // Proceed with saving if there are no errors
    setActionMode(mode);
    setIsEditVisible(false);
    setIsPreviewVisible(true);
  };

  const handleConfirmClick = () => {
    if (actionMode === "ADD") {
      handleConfirm();
    } else if (actionMode === "UPDATE") {
      handleUpdate();
    } else if (actionMode === "DELETE") {
      handleDelete();
    }

    setIsPreviewVisible(false);
    setIsConfirmVisible(true); // Show the Confirm grid when Confirm button is clicked
  };

  const removeFromCart = (inventoryid) => {
    const updatedCart = { ...storedCart };
    delete updatedCart[inventoryid];
    setStoredCart({ ...updatedCart }); // Ensure immutability when updating state
  };

  const removeComboCart = (id) => {
    const updatedCart = { ...comboCart };
    delete updatedCart[id];
    setComboCart({ ...updatedCart }); // Ensure immutability when updating state
  };

  const isStoredCartEmpty = (storedCart) => {
    return (
      storedCart === null ||
      (typeof storedCart === "object" && Object.keys(storedCart).length === 0)
    );
  };
  const isComboCartEmpty = (comboCart) => {
    return (
      comboCart === null ||
      (typeof comboCart === "object" && Object.keys(comboCart).length === 0)
    );
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <CustomDrawer
        anchor="top" // Opens from the top
        renderContent={renderDrawerContent} // Pass the render function
        isOpen={isDrawerOpen} // Control the open state
        toggleDrawer={toggleDrawer} // Toggle function passed to drawer
        sx={{
          "& .MuiDrawer-paper": {
            height: {
              xs: "90vh", // For extra-small and small devices
              sm: "70vh", // For small to medium devices
              lg: "350vh", // For medium and larger devices
            },
            width: "100%", // Full width for all screen sizes
          },
        }}
      />

      <CustomDrawer
        anchor="left"
        renderContent={renderComboDrawerContent} // Pass the render function
        isOpen={isComboDrawerOpen} // Control the open state
        toggleDrawer={toggleComboDrawer} // Toggle function passed to drawer
      />
      {isEditVisible && (
        <Grid container spacing={2} id="EDIT">
          <Grid item xs={12} md={6}>
            <DeliveryDetails
              form={form}
              handleChange={handleChange}
              isEditMode={false}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {false && (
              <SearchWithDropdown
                onSelectItem={(item) => {
                  // Example logic when an item is selected
                  console.log(`Selected item ID: ${item.inventoryid}`);
                  addToCart(item.inventoryid); // Add to cart or any other logic
                }}
              />
            )}

            {isStoredCartEmpty(storedCart) && (
              <Stack spacing={1}>
                <Skeleton variant="rectangular" animation="wave" height={60} />
                <Skeleton variant="rectangular" animation="wave" height={60} />
              </Stack>
            )}
            {errorMessage && (
              <Typography
                sx={{ color: "red", marginTop: 2, textAlign: "center" }}
              >
                {errorMessage}
              </Typography>
            )}

            <CustomButton
              label="View Inventory Items"
              variant="text"
              color="primary"
              size="large"
              onClick={toggleDrawer(true)}
            />
            <CustomButton
              label="View Combo Items"
              variant="text"
              color="primary"
              size="large"
              onClick={toggleComboDrawer(true)}
            />
            <OrderSummary
              setItems={setItemsInForm}
              setStoredCartItems={setStoredCartItems}
              setCoupon={setCouponInForm}
              setCartPrice={setCartPriceInForm}
              cartItems={storedCart}
              coupon={form.coupon}
              action="Sales"
              onRemove={removeFromCart}
              comboCart={comboCart}
              removeComboItem={removeComboCart}
              deliveryState={form.state}
              setAutoDiscountsInParent={setAutoDiscounts}
              setExtraDiscountInParent={handleExtraDiscountChange}
              extraDiscountForm={form.extraDiscount}
              setExtraDiscountDescriptionInParent={
                handleExtraDiscountDescriptionChange
              }
              extraDiscountDes={form.extraDiscountDescription}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {isEditing ? (
              <>
                <CustomButton
                  label="Update Sales"
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => handleSaveSales("UPDATE")}
                />
                <CustomButton
                  label="Cancel"
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => {
                    resetMode();
                    resetForm();
                  }}
                  
                />
                <CustomButton
                  label="Delete Sales"
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => handleSaveSales("DELETE")}
                />
              </>
            ) : (
              <CustomButton
                label="Save Sales"
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => handleSaveSales("ADD")}
              />
            )}
          </Grid>
        </Grid>
      )}
      {/* Preview Grid */}
      {isPreviewVisible && (
        <Grid container spacing={2} id="PREVIEW">
          <Grid item xs={12} md={6}>
            <Typography variant="h6">Preview Sales</Typography>
            <DeliveryDetails
              form={form}
              handleChange={handleChange}
              isEditMode={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OrderSummary
              setItems={setItemsInForm}
              setStoredCartItems={setStoredCartItems}
              setCartPrice={setCartPriceInForm}
              cartItems={storedCart}
              action="Checkout"
              setCoupon={setCouponInForm}
              coupon={form.coupon}
              comboCart={comboCart}
              deliveryState={form.state}
              setAutoDiscountsInParent={setAutoDiscounts}
              setExtraDiscountInParent={handleExtraDiscountChange}
              extraDiscountForm={extraDiscount}
            />
          </Grid>
          <Grid item xs={12}>
            <CustomButton
              label="Confirm"
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleConfirmClick}
            />
          </Grid>
        </Grid>
      )}

      {/* Confirm Grid */}
      {isConfirmVisible && (
        <Grid container spacing={3} id="CONFIRM">
          <Grid item xs={12}>
            <Typography variant="h6">Congratulations</Typography>
            <CustomButton
              label="Back to Sales"
              variant="outlined"
              color="primary"
              size="large"
              onClick={resetMode}
            />
            {/* Additional confirmation details can be added here */}
          </Grid>
        </Grid>
      )}

      <Grid
        container
        spacing={3}
        sx={{
          marginTop: "10px",
        }}
      >
        {/* Pre-shipment List */}
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Pre Shipment List
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SalesTable
            sales={sales}
            handleEdit={handleEdit}
            handleViewClick={handleViewClick}
          />
        </Grid>

        {selectedSale && (
          <ModalBox
            open={openItemsDetailsModal}
            handleClose={() => setOpenItemsDetailsModal(false)}
            direction="up"
          >
            <SalesCardwithCarousel
              items={selectedSale.items}
              itemsList={items}
              name={selectedSale.name}
              buyerdetails={selectedSale.buyerDetails}
              totalprice={selectedSale.price}
              phonenumber={selectedSale.phoneNumber}
            />
          </ModalBox>
        )}
      </Grid>
    </Box>
  );
}
