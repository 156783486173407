import React, { useState, useEffect, useContext, createContext } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Box, Container, CircularProgress } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import MenuBar from "./components/MenuBar/MenuBar";
import InventoryPage from "./components/InventoryPage/Inventory";
import SalesRecord from "./components/SalesRecordPage/SalesRecord";
import Checkout from "./components/SalesList/Checkout";
import SalesList from "./components/SalesList/SalesList";
import ProductDetails from "./components/SalesList/ProductDetails";
import Cart from "./components/SalesList/Cart";
import GlobalProvider from "./GlobalProvider";
import ShipmentPage from "./components/ShipmentPage/shipment";
import InventoryDetails from "./components/InventoryDetailsPage/InventoryDetails";
import Analytics from "./components/AnalyticsPage/Analytics";
import ParentComponent from "./components/InventoryManagement/ParentComponent";
import WelcomePage from "./components/WelcomePage/WelcomePage";
import Footer from "./components/Footer/Footer";
import ComboOffer from "./components/ComboOffer/ComboOffer";
import ComboOfferDetailsPage from "./components/ComboOffer/ComboOfferDetailsPage";
import AdminIconMenu from "./components/AdminIconMenu/AdminIconMenu";
import HeaderGrid from "./components/Header/HeaderGrid";
import { API_BASE_URL, IMAGE_BASE_URL } from "./config/constants";
import { ToastProvider } from "./utility-v1/ToastProvider";
import GoogleLoginComponent from "./utility-v1/GoogleLoginComponent";
import PrivateRoute from "./PrivateRoute";

const ImageCacheContext = createContext();
export const useImageCache = () => useContext(ImageCacheContext);

const HeaderGridWrapper = ({ user }) => {
  const location = useLocation();
  const pathArray = location.pathname.split("/").filter(Boolean);

  if (pathArray[0] !== "welcome") {
    return (
      <HeaderGrid
        pathArray={pathArray}
        content=""
       // additionalContent={user ? <AdminIconMenu /> : null} // Show only if user is logged in
        additionalContent={<AdminIconMenu />} // Show only if user is logged in
      />
    );
  }
  return null;
};

function App() {
  const [allItems, setAllItems] = useState([]);
  const [imageCache, setImageCache] = useState(new Map());
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);


  // Fetch and cache images
  useEffect(() => {
    const fetchAndCacheImages = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/filtered-items`);
        const data = await response.json();

        const cache = new Map();

        // Preload images
        await Promise.all(
          data.map((item) => {
            const img = new Image();
            img.src = `${IMAGE_BASE_URL}${item.image}`;
            return new Promise((resolve, reject) => {
              img.onload = () => {
                cache.set(item.image, img.src);
                resolve();
              };
              img.onerror = reject;
            });
          })
        );

        setAllItems(data);
        setImageCache(cache);
      } catch (error) {
        console.error("Error fetching items or caching images:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAndCacheImages();
  }, []);

  // Show Google login if user is not authenticated

  // Show loading spinner while fetching data
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Render app after user login and loading complete
  return (
    <ImageCacheContext.Provider value={imageCache}>
      <Router>
        <CssBaseline />
        <GlobalProvider>
          <Container maxWidth="lg">
            <ToastProvider>
              <MenuBar />
              <HeaderGridWrapper user={user} />

              <Box>
                <Routes>
                  <Route
                    path="/adminlogin"
                    element={<GoogleLoginComponent setUserParent={setUser} />}
                  />
                  <Route path="/welcome" element={<WelcomePage />} />
                  <Route
                    path="/list/:category"
                    element={<SalesList allItems={allItems} />}
                  />
                  <Route
                    path="/list/:category/:id"
                    element={<ProductDetails />}
                  />
                  <Route path="/checkout" element={<Checkout />} />
                  <Route path="/bag" element={<Cart />} />
                  <Route path="/combooffer" element={<ComboOffer />} />
                  <Route
                    path="/combooffer/:id"
                    element={<ComboOfferDetailsPage />}
                  />
                  <Route path="/inventory" element={<InventoryPage />} />
                  <Route path="/sales" element={<SalesRecord />} />
                  <Route path="/shipment" element={<ShipmentPage />} />
                  <Route path="/inventory-details" element={<InventoryDetails />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/admin" element={<ParentComponent />} />
                    {/* Commenting out PrivateRoute */}
  {/* 
                  <Route
                    path="/inventory"
                    element={
                      <PrivateRoute user={user}>
                        <InventoryPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/sales"
                    element={
                      <PrivateRoute user={user}>
                        <SalesRecord />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/shipment"
                    element={
                      <PrivateRoute user={user}>
                        <ShipmentPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/inventory-details"
                    element={
                      <PrivateRoute user={user}>
                        <InventoryDetails />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/analytics"
                    element={
                      <PrivateRoute user={user}>
                        <Analytics />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <PrivateRoute user={user}>
                        <ParentComponent />
                      </PrivateRoute>
                    }
                  />
                    */}

                </Routes>
              </Box>
            </ToastProvider>
          </Container>
        </GlobalProvider>
      </Router>
    </ImageCacheContext.Provider>
  );
}

export default App;
