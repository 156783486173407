import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ItemDetailsCard from "./../ItemCard/ItemDetailsCard";
import { API_BASE_URL, IMAGE_BASE_URL } from "../../config/constants";
import CustomButton from "./../../utility-v1/Button";
import TextInput from "./../../utility-v1/TextInput";
import SelectBox from "./../../utility-v1/SelectBox";
import {
  itemPriority,
  itemDiscount,
  webStatus,
  webCategory,
  boxDetails,
  itemCategory,
} from "../../config/constantselections";
import AddComboDialog from "./AddComboDialog";

const InventoryDetails = () => {
  const [items, setItems] = useState([]);
  const [itemSalesQuantities, setItemSalesQuantities] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [boxNoFilter, setBoxNoFilter] = useState("");
  const [balanceFilter, setBalanceFilter] = useState("");
  const [selectedItems, setSelectedItems] = useState({});
  const [latestSelectedItems, setLatestSelectedItems] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [buyingPriceMin, setBuyingPriceMin] = useState("");
  const [buyingPriceMax, setBuyingPriceMax] = useState("");
  const [sellingPriceMin, setSellingPriceMin] = useState("");
  const [sellingPriceMax, setSellingPriceMax] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [discountFilter, setDiscountFilter] = useState("");
  const [priorityFilter, setPriorityFilter] = useState("");
  const [webStatusFilter, setWebStatusFilter] = useState("");
  const [webCategoryFilter, setWebCategoryFilter] = useState("");
  const [isAddComboDialogOpen, setIsAddComboDialogOpen] = useState(false);

  const handleOpenAddComboDialog = () => {
    const currentlySelectedItems = Object.keys(selectedItems).filter(
      (id) => selectedItems[id]
    );
    setLatestSelectedItems(currentlySelectedItems);
    setIsAddComboDialogOpen(true);
  };

  const handleCloseAddComboDialog = () => {
    setIsAddComboDialogOpen(false);
  };

  const [dialogForm, setDialogForm] = useState({
    discount: "",
    priority: "",
    boxno: "",
    webstatus: "",
    webcategory: "",
  });

  let purchasePriceTotal = 0;
  let quantityTotal = 0;
  let purchasePriceLeftTotal = 0;
  let quantityLeftTotal = 0;

  const fetchData = async () => {
    try {
      const itemRespons2 = await axios.get(`${API_BASE_URL}/orderdetails`);
      setLatestSelectedItems(itemRespons2.data);
      const response = await axios.get(`${API_BASE_URL}/items`);
      setItems(response.data || []);
      fetchItemSalesQuantity(response.data);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  const fetchItemSalesQuantity = async (itemsData) => {
    try {
      // Fetch sales records
      const response = await axios.get(`${API_BASE_URL}/salesrecords`);
      const salesData = response.data || [];
  
      // Group sales by inventoryid for efficient lookup
      const salesByInventoryId = salesData.reduce((acc, sale) => {
        acc[sale.inventoryid] = (acc[sale.inventoryid] || 0) + sale.quantity;
        return acc;
      }, {});
  
      // Compute total quantities and sales for each item
      const itemQuantities = itemsData.reduce((acc, { inventoryid, price }) => {
        const totalQuantity = salesByInventoryId[inventoryid] || 0; // Get total sales quantity
        const totalSales = totalQuantity * (price || 0); // Calculate total sales
        acc[inventoryid] = { totalQuantity, totalSales }; // Store results
        return acc;
      }, {});
  
      // Update the state with the computed quantities
      setItemSalesQuantities(itemQuantities);
    } catch (error) {
      console.error("Failed to fetch sales data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectItem = (inventoryid) => {
    setSelectedItems((prev) => ({
      ...prev,
      [inventoryid]: !prev[inventoryid],
    }));
  };

  const handleSelectAll = () => {
    const newSelectedItems = {};
    filteredItems.forEach((item) => {
      newSelectedItems[item.inventoryid] = true;
    });
    setSelectedItems(newSelectedItems);
  };

  const handleDeselectAll = () => {
    const newSelectedItems = {};
    filteredItems.forEach((item) => {
      newSelectedItems[item.inventoryid] = false;
    });
    setSelectedItems(newSelectedItems);
  };

  const handleOpenDialog = () => {
    const currentlySelectedItems = Object.keys(selectedItems).filter(
      (id) => selectedItems[id]
    );
    setLatestSelectedItems(currentlySelectedItems);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleDialogChange = (e) => {
    const { name, value } = e.target;
    setDialogForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateWeb = () => {
    latestSelectedItems.forEach(async (id) => {
      const selectedItem = items.find((item) => item.inventoryid === id);

      if (selectedItem) {
        const updatedItem = {
          ...selectedItem,
          discount:
            dialogForm.discount !== ""
              ? parseInt(dialogForm.discount, 10)
              : selectedItem.discount,
          priority:
            dialogForm.priority !== ""
              ? parseInt(dialogForm.priority, 10)
              : selectedItem.priority,
          boxno:
            dialogForm.boxno !== ""
              ? parseInt(dialogForm.boxno, 10)
              : selectedItem.boxno,
          webstatus:
            dialogForm.webstatus !== ""
              ? parseInt(dialogForm.webstatus, 10)
              : selectedItem.webstatus,
          webcategory:
            dialogForm.webcategory !== ""
              ? parseInt(dialogForm.webcategory, 10)
              : selectedItem.webcategory,
        };

        try {
          await axios.put(`${API_BASE_URL}/webitems/${id}`, updatedItem);
          console.log(`Item ${id} updated successfully.`);
        } catch (error) {
          console.error(`Failed to update item ${id}:`, error);
        }
      }
    });

    setIsDialogOpen(false);
  };

  const handleSearchChange = (e) => setSearchTerm(e.target.value);
  const handleBoxNoFilterChange = (e) => setBoxNoFilter(e.target.value);
  const handleBalanceFilterChange = (e) => setBalanceFilter(e.target.value);
  const handleBuyingPriceMinChange = (e) => setBuyingPriceMin(e.target.value);
  const handleBuyingPriceMaxChange = (e) => setBuyingPriceMax(e.target.value);
  const handleSellingPriceMinChange = (e) => setSellingPriceMin(e.target.value);
  const handleSellingPriceMaxChange = (e) => setSellingPriceMax(e.target.value);
  const handleTypeFilterChange = (e) => setTypeFilter(e.target.value);
  const handleDiscountFilterChange = (e) => setDiscountFilter(e.target.value);
  const handlePriorityFilterChange = (e) => setPriorityFilter(e.target.value);
  const handleWebStatusFilterChange = (e) => setWebStatusFilter(e.target.value);
  const handleWebCategoryFilterChange = (e) =>
    setWebCategoryFilter(e.target.value);

  const resetFilters = () => {
    setSearchTerm("");
    setBoxNoFilter("");
    setBalanceFilter("");
    setBuyingPriceMin("");
    setBuyingPriceMax("");
    setSellingPriceMin("");
    setSellingPriceMax("");
    setTypeFilter("");
    setDiscountFilter("");
    setPriorityFilter("");
    setWebStatusFilter("");
    setWebCategoryFilter("");
  };

  // Filter items based on the search term and other filters
  const filteredItems = items.filter((item) => {
    const originalInventory = parseInt(item.quantity);
    const itemSalesData = itemSalesQuantities[item.inventoryid] || {
      totalQuantity: 0,
      totalSales: 0,
    };
    const balance = originalInventory - itemSalesData.totalQuantity;

    const label = item.label ? item.label.toString() : "";
    const matchesSearch =
      searchTerm === "" ||
      label.toLowerCase().includes(searchTerm.toLowerCase());

    const matchesBoxNo =
      boxNoFilter === "" || item.boxno.toString() === boxNoFilter;
    const balanceFilterNumber =
      balanceFilter === "" ? NaN : parseInt(balanceFilter);
    const matchesBalance =
      isNaN(balanceFilterNumber) || balance === balanceFilterNumber;

    const price = item.price || 0;
    const sellingprice = item.sellingprice || 0;
    const matchesBuyingPriceMin =
      buyingPriceMin === "" || price >= parseInt(buyingPriceMin);
    const matchesBuyingPriceMax =
      buyingPriceMax === "" || price <= parseInt(buyingPriceMax);
    const matchesSellingPriceMin =
      sellingPriceMin === "" || sellingprice >= parseInt(sellingPriceMin);
    const matchesSellingPriceMax =
      sellingPriceMax === "" || sellingprice <= parseInt(sellingPriceMax);

    // Additional filters for discount, priority, webstatus, webcategory, and type
    const matchesDiscount =
      discountFilter === "" || item.discount === parseInt(discountFilter);
    const matchesPriority =
      priorityFilter === "" || item.priority === parseInt(priorityFilter);
    const matchesWebStatus =
      webStatusFilter === "" || item.webstatus === parseInt(webStatusFilter);
    const matchesWebCategory =
      webCategoryFilter === "" || item.webcategory === webCategoryFilter;
    const matchesType = typeFilter === "" || item.type === typeFilter;

    return (
      matchesSearch &&
      matchesBoxNo &&
      matchesBalance &&
      matchesBuyingPriceMin &&
      matchesBuyingPriceMax &&
      matchesSellingPriceMin &&
      matchesSellingPriceMax &&
      matchesDiscount &&
      matchesPriority &&
      matchesWebStatus &&
      matchesWebCategory &&
      matchesType
    );
  });

  filteredItems.forEach((item) => {
    const originalInventory = parseInt(item.quantity);
    const itemSalesData = itemSalesQuantities[item.inventoryid] || {
      totalQuantity: 0,
      totalSales: 0,
    };
    const balance = originalInventory - itemSalesData.totalQuantity;
    const price = item.price || 0;

    quantityTotal += originalInventory;
    purchasePriceTotal += originalInventory * price;
    quantityLeftTotal += balance;
    purchasePriceLeftTotal += balance * price;
  });

  return (
    <Box className="inventory-details" padding={2}>
      <Typography variant="h4" gutterBottom>
        Inventory Details
      </Typography>

      <Grid container spacing={2} marginBottom={2}>
        <Grid item xs={12} sm={4}>
          <TextInput
            label="Item Code"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectBox
            label="Type"
            value={typeFilter}
            onChange={handleTypeFilterChange}
            options={itemCategory}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectBox
            label="Box No"
            value={boxNoFilter}
            onChange={handleBoxNoFilterChange}
            options={boxDetails}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            label="Q Left"
            value={balanceFilter}
            onChange={handleBalanceFilterChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            label="Buying Price Min"
            value={buyingPriceMin}
            onChange={handleBuyingPriceMinChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            label="Buying Price Max"
            value={buyingPriceMax}
            onChange={handleBuyingPriceMaxChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            label="Selling Price Min"
            value={sellingPriceMin}
            onChange={handleSellingPriceMinChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextInput
            label="Selling Price Max"
            value={sellingPriceMax}
            onChange={handleSellingPriceMaxChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectBox
            label="Discount"
            value={discountFilter}
            onChange={handleDiscountFilterChange}
            options={itemDiscount}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectBox
            label="Priority"
            value={priorityFilter}
            onChange={handlePriorityFilterChange}
            options={itemPriority}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectBox
            label="Web Status"
            value={webStatusFilter}
            onChange={handleWebStatusFilterChange}
            options={webStatus}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SelectBox
            label="Web Category"
            value={webCategoryFilter}
            onChange={handleWebCategoryFilterChange}
            options={webCategory}
          />
        </Grid>
      </Grid>
      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          {/* Quantity Total */}
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1">Total Quantity</Typography>
            <Typography variant="h6">{quantityTotal}</Typography>
          </Grid>

          {/* Purchase Price Total */}
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1">Total Purchase Price</Typography>
            <Typography variant="h6">
              ₹{purchasePriceTotal.toFixed(2)}
            </Typography>
          </Grid>

          {/* Quantity Left Total */}
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1">Quantity Left</Typography>
            <Typography variant="h6">{quantityLeftTotal}</Typography>
          </Grid>

          {/* Purchase Price Left Total */}
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1">Purchase Price Left</Typography>
            <Typography variant="h6">
              ₹{purchasePriceLeftTotal.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          {/* Quantity Total */}
          <Grid item xs={6} md={3}>
            <CustomButton
              variant="outlined"
              label="Select All"
              color="primary"
              onClick={handleSelectAll}
            />
          </Grid>

          {/* Purchase Price Total */}
          <Grid item xs={6} md={3}>
            <CustomButton
              variant="outlined"
              label="Deselect All"
              color="primary"
              onClick={handleDeselectAll}
            />
          </Grid>

          {/* Quantity Left Total */}
          <Grid item xs={6} md={3}>
            <CustomButton
              variant="outlined"
              label="Update Web"
              color="primary"
              onClick={handleOpenDialog}
            />
          </Grid>

          {/* Purchase Price Left Total */}
          <Grid item xs={6} md={3}>
            <CustomButton
              variant="outlined"
              label="Add Combo"
              color="primary"
              onClick={handleOpenAddComboDialog}
              disabled={
                Object.keys(selectedItems).filter((id) => selectedItems[id])
                  .length === 0
              }
            />
          </Grid>
        </Grid>
      </Box>

      {/* Items List */}
      <Grid container spacing={2}>
        {filteredItems.map((item) => {
          const originalInventory = parseInt(item.quantity) || 0;
          const itemSalesData = itemSalesQuantities[item.inventoryid] || {
            totalQuantity: 0,
            totalSales: 0,
          };
          const reducedInventory = itemSalesData.totalQuantity; // Extract totalQuantity
          const balance = originalInventory - reducedInventory;
          const price = item.price || 0;
          const sellingprice = item.sellingprice || 0;

          return (
            <Grid item xs={12} sm={6} md={3} key={item.inventoryid}>
              <ItemDetailsCard
                inventoryid={item.inventoryid}
                title={item.code}
                image={item.image}
                itemcode={item.label}
                quantity={originalInventory}
                boxno={item.boxno}
                price={price}
                sellingprice={sellingprice}
                balancequantity={balance}
                totalsold={reducedInventory} // Pass the totalQuantity here
                isSelected={!!selectedItems[item.inventoryid]}
                onSelectItem={handleSelectItem}
              />
            </Grid>
          );
        })}
      </Grid>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Selected Items</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {latestSelectedItems.map((id, index) => {
              const selectedItem = items.find(
                (item) => item.inventoryid === id
              );
              return selectedItem ? (
                <Grid item xs={12} key={id}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={1}>
                      <Typography variant="body2">{index + 1}</Typography>{" "}
                      {/* Serial Number */}
                    </Grid>
                    <Grid item xs={2}>
                      <img
                        src={`${IMAGE_BASE_URL}${selectedItem.image}`}
                        alt={selectedItem.label}
                        style={{ width: 50, height: 50 }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2">
                        {selectedItem.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null;
            })}

            <Grid item xs={12}>
              <SelectBox
                label="Discount"
                value={dialogForm.discount}
                name="discount"
                onChange={handleDialogChange}
                options={itemDiscount}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectBox
                label="Priority"
                value={dialogForm.priority}
                name="priority"
                onChange={handleDialogChange}
                options={itemPriority}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectBox
                label="Box No"
                value={dialogForm.boxno}
                name="boxno"
                onChange={handleDialogChange}
                options={boxDetails}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectBox
                label="Web Status"
                value={dialogForm.webstatus}
                name="webstatus"
                onChange={handleDialogChange}
                options={webStatus}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectBox
                label="Web Category"
                value={dialogForm.webcategory}
                name="webcategory"
                onChange={handleDialogChange}
                options={webCategory}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleUpdateWeb} variant="contained" color="primary">
            Update Web
          </Button>
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <AddComboDialog
        open={isAddComboDialogOpen}
        onClose={handleCloseAddComboDialog}
        selectedItemIds={latestSelectedItems}
        items={items}
      />
    </Box>
  );
};

export default InventoryDetails;
