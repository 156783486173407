import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Link,
  Typography
} from '@mui/material';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';

export default function SalesTable({ sales, handleEdit, handleDelete, handleViewClick }) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><Typography variant="h6">#</Typography></TableCell>
            <TableCell><Typography variant="h6">Name</Typography></TableCell>
            <TableCell><Typography variant="h6">Price</Typography></TableCell>
            <TableCell><Typography variant="h6">Actions</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sales.map((sale, index) => (
            <TableRow key={sale.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell><Link href="#" onClick={(e) => handleViewClick(e, sale)}>{sale.name}</Link></TableCell>
              <TableCell>{sale.price}₹</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEdit(sale.id)} color="primary">
                  <Edit />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
