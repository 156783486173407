import React from "react";
import { Box, Typography } from "@mui/material";

const SeasonGreetings = () => (
  <Box
    sx={{
      width: "100%",
      height: "100px",
      backgroundColor: "#ccc",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }}
  >
    <Typography
      variant="h6"
      sx={{
        color: "#000",
        fontWeight: "bold",
        textAlign: "center"
      }}
    >
      Welcome to Illolam Jewels - Celebrate the Festive Season with Us!
    </Typography>
  </Box>
);

export default SeasonGreetings;
