import React from 'react';
import { Box, Typography, Card, CardContent, Button } from '@mui/material';
import CelebrationIcon from '@mui/icons-material/Celebration';
import { FONT_FAMILY } from "../../config/themeConstants"; // Import FONT_FAMILY

const ConfirmationPage = ({ orderNo, onContinueShopping }) => {
  return (
    <Card
      elevation={4}
      sx={{
        maxWidth: "100%",
        margin: 'auto',
        mt: 4,
        borderRadius: 4,
        background: 'linear-gradient(135deg, #f5f7fa, #c3cfe2)',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
        textAlign: 'center',
        padding: 3,
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <CelebrationIcon sx={{ fontSize: 50, color: '#f50057' }} />
        </Box>
        <Typography variant="h4" gutterBottom sx={{ fontFamily: FONT_FAMILY,fontWeight: 600, color: '#3f51b5' }}>
          🎉 Order Confirmed! 🎉
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: FONT_FAMILY,fontSize: 18, color: '#333', mb: 2,textAlign: 'left' }}>
          Congratulations on your great choice! Your order (No. <strong>{orderNo}</strong>) has been successfully placed, and we’re thrilled to have you as a customer.
        </Typography>
        <Typography variant="body1" sx={{ fontFamily: FONT_FAMILY,fontSize: 18, color: '#333', mb: 4,textAlign: 'left' }}>
          Our team will soon verify your order, then begin carefully packaging and preparing it for shipment. Stay tuned – your purchase is on its way to you! 🛍️✨
        </Typography>
        <Button
          onClick={onContinueShopping}
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            borderRadius: 20,
            paddingX: 4,
            textTransform: 'capitalize',
            fontWeight: 500,
          }}
        >
          Continue Shopping
        </Button>
      </CardContent>
    </Card>
  );
};

export default ConfirmationPage;
