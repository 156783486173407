import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import TextInput from "./../../utility-v1/TextInput";
import SelectBox from "./../../utility-v1/SelectBox";
import { FONT_FAMILY } from "../../config/themeConstants"; // Import FONT_FAMILY

import {
  indianStates
} from "../../config/constantselections";

const DeliveryDetails = ({ form, handleChange, isEditMode, errors }) => {

  const getStateLabel = (value) => {
    const state = indianStates.find((state) => state.value === value);
    return state ? state.label : "";
  };

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: FONT_FAMILY,
          fontSize: "20px",
          fontWeight: 500,
        }}
      >
        {isEditMode ? "Preview Delivery Details" : "Delivery Details"}
      </Typography>

      <Grid container spacing={3}>
        {!isEditMode ? (
          <>
            <Grid item xs={12}>
              <TextInput
                label="Full Name"
                name="name"
                value={form.name}
                onChange={handleChange}
                error={errors.name}
                helperText={errors.name && "Please enter full name."}
              />
            </Grid>

            <Grid item xs={6}>
              <TextInput
                label="Address"
                name="buyerDetails"
                value={form.buyerDetails}
                onChange={handleChange}
                multiline
                rows={5}
                error={errors.buyerDetails}
                helperText={errors.buyerDetails && "Please enter address."}
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextInput
                    label="Pin Code"
                    name="pincode"
                    value={form.pincode}
                    onChange={handleChange}
                    error={errors.pincode}
                    helperText={errors.pincode && "Please enter pincode."}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectBox
                    label="States"
                    value={form.state}
                    name="state"
                    onChange={handleChange}
                    options={indianStates}
                    error={errors.state}
                    helperText={errors.state && "Please enter state."}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label="Phone Number"
                name="phoneNumber"
                value={form.phoneNumber}
                onChange={handleChange}
                error={errors.phoneNumber}
                helperText={errors.phoneNumber && "Please enter phone number."}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                label="Email"
                name="email"
                value={form.email}
                onChange={handleChange}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    p: 2,
                    border: "1px solid #ddd",
                    borderRadius: "4px",
                    backgroundColor: "#f9f9f9",
                    mt: 0,
                  }}
                >
                  {" "}
                  <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                    <strong>Name:</strong> {form.name || "N/A"}
                  </Typography>
                  <Typography variant="body1" sx={{ whiteSpace: "pre-line" }}>
                    <strong>Address:</strong> {form.buyerDetails || "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Pin Code:</strong> {form.pincode || "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>State:</strong> {getStateLabel(form.state) || "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Phone Number:</strong> {form.phoneNumber || "N/A"}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Email:</strong> {form.email || "N/A"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default DeliveryDetails;
