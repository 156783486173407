import React from 'react';
import { Grid, Checkbox, Typography, IconButton } from '@mui/material';
import TextInput from './../../utility-v1/TextInput';
import IconHyperlink from './../../utility-v1/IconHyperlink';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

const ShipmentCard = ({ sale, items, shipmentDetails, handleShipmentChange, handleSubmit, handleRevert, isEditing, handleCheckboxChange, isChecked, index }) => {
  const itemDetails = Object.keys(sale.items).map(itemId => {
    const item = items.find(item => item.inventoryid === itemId);
    return item ? item.code : '';
  }).filter(item => item !== '');

  return (
    <div className="card">
      <Grid container alignItems="center" spacing={2}>
        {/* Index */}
        <Grid item xs={1}>
          <Typography variant="h6" align="center">
            <strong>{index + 1}</strong>
          </Typography>
        </Grid>

        {/* Sale Name */}
        <Grid item xs={3}>
          <Typography variant="body1" style={{ marginTop: '10px' }}>
            <strong>{sale.name}</strong>
          </Typography>
        </Grid>

        {/* Shipment Price Input */}
        <Grid item xs={3}>
          <TextInput
            label="Price"
            name="shipmentPrice"
            value={shipmentDetails[sale.id]?.shipmentPrice || ''}
            onChange={(e) => handleShipmentChange(e, sale.id)}
            placeholder="Enter shipment price"
            error={false}
            helperText="Please enter shipment price"
          />
        </Grid>

        {/* Submit (Shipping Icon) */}
        <Grid item xs={2} align="center">
          <IconButton
            color="primary"
            onClick={() => handleSubmit(sale.id)}
          >
            <LocalShippingIcon />
          </IconButton>
        </Grid>

        {/* Checkbox */}
        <Grid item xs={2}>
          <Checkbox
            checked={isChecked}
            onChange={() => handleCheckboxChange(sale.id)}
            color="primary"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShipmentCard;
