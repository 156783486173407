import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, IconButton } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddIcon from "@mui/icons-material/Add"; // Import AddIcon
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { API_BASE_URL, IMAGE_BASE_URL } from "../../config/constants"; // Import API_BASE_URL
import { FONT_FAMILY } from "../../config/themeConstants";
import dayjs from "dayjs"; // Import dayjs

const ComboOffer = ({ callingPage, onSelectCombo }) => {
  const [comboOffers, setComboOffers] = useState([]);
  const [itemsData, setItemsData] = useState({}); // Store fetched item details
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Fetch combo offers and item details from the API
  useEffect(() => {
    const fetchComboOffers = async () => {
      try {
        // Fetch combo offers
        const comboResponse = await axios.get(`${API_BASE_URL}/combo`);
        const combos = comboResponse.data; // Assuming data is an array of combo offers

        // Fetch item details
        const itemResponse = await axios.get(`${API_BASE_URL}/items`);
        const items = itemResponse.data;

        // Create a map of item details based on item IDs
        const itemDetailsMap = items.reduce((acc, item) => {
          acc[item.inventoryid] = item; // Map item by inventoryid
          return acc;
        }, {});

        // Set combo offers and item details
        setComboOffers(combos);
        setItemsData(itemDetailsMap);
      } catch (error) {
        console.error("Error fetching combo offers or items", error);
      }
    };

    fetchComboOffers();
  }, []);

  const handleAddToCart = (combo) => {
    const storageKey = "comboCart";
    const comboCart = JSON.parse(localStorage.getItem(storageKey)) || {};

    const cartItem = {
      comboId: combo.id,
      comboname: combo.comboname,
      price: Number(combo.price),
      items: combo.items.map((itemId) => {
        const item = itemsData[itemId];
        return {
          inventoryid: item.inventoryid,
          label: item.label,
          image: item.image,
          price: item.price,
          sellingprice: item.sellingprice,
          discount: item.discount,
          quantity: 1,
        };
      }),
    };

    // Update the combo cart
    comboCart[combo.id] = cartItem;

    // Conditionally call setComboOffers if on "Admin" page
    if (callingPage === "Admin") {
      onSelectCombo(comboCart); // Pass the updated comboCart to setComboOffers
    } else {
      localStorage.setItem(storageKey, JSON.stringify(comboCart));
    }

    console.log(`Combo ${combo.comboname} added to cart`, comboCart);
  };

  const handleComboClick = (comboId) => {
    navigate(`/combooffer/${comboId}`); // Navigate to the ComboOfferDetailsPage
  };

  return (
    <Box>
      <Grid container spacing={4}>
        {comboOffers.map((combo) => (
          <Grid item xs={12} md={4} key={combo.id}>
            {/* Make the entire box clickable to navigate to the combo details */}
            <Box
              onClick={() => handleComboClick(combo.id)} // Navigate on click
              sx={{
                border: "1px solid #ccc",
                borderRadius: "10px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                position: "relative",
                cursor: "pointer", // Show a pointer cursor to indicate it's clickable
                p: 1,
              }}
            >
              {/* Expires On (top right) */}
              <Typography
                sx={{
                  position: "absolute",
                  top: "-15px",
                  right: "10px",
                  backgroundColor: "#fff",
                  padding: "2px 8px",
                  borderRadius: "5px",
                  fontFamily: { FONT_FAMILY },
                  color: "#000",
                  fontSize: "12px",
                  fontWeight: 600,
                }}
              >
                Expires On: {dayjs(combo.expirationdate).format("DD/MM/YYYY")}
              </Typography>

              {/* Combo Name (top left) */}
              <Typography
                sx={{
                  position: "absolute",
                  top: "-15px",
                  left: "10px",
                  backgroundColor: "#fff",
                  padding: "2px 8px",
                  borderRadius: "5px",
                  fontFamily: { FONT_FAMILY },
                  color: "#000",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {combo.comboname}
              </Typography>

              {/* Combo Price */}
              <Grid container spacing={2} sx={{ mt: 0 }}>
                {combo.items.map((itemId) => {
                  const item = itemsData[itemId]; // Get item details from the map
                  return (
                    item && (
                      <Grid item xs={6} sm={6} key={itemId}>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            component="img"
                            src={IMAGE_BASE_URL + item.image} // Assuming `item.image` contains the image URL
                            alt={item.name}
                            sx={{
                              width: "150px",
                              height: "200px",
                              objectFit: "cover",
                              borderRadius: "8px",
                            }}
                          />
                          <Box
                            sx={{
                              alignItems: "left",
                              width: "100%",
                              ml: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: { FONT_FAMILY },
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: 600,
                              }}
                            >
                              {item.label}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: { FONT_FAMILY },
                                color: "#000",
                                fontSize: "13px",
                                fontWeight: 600,
                              }}
                            >
                              ₹{Number(item.price).toFixed(2)}{" "}
                              {/* Ensure price is a number */}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  );
                })}
              </Grid>
              <Typography
                sx={{
                  fontFamily: { FONT_FAMILY },
                  color: "teal",
                  fontSize: "13px",
                  fontWeight: 600,
                  ml:'3px',
                  mt:1
                }}
              >
                Combo Price: ₹{Number(combo.price).toFixed(2)}{" "}
                {/* Display combo price */}
              </Typography>
              {/* Add to Cart Button */}
              <IconButton
                sx={{ position: "absolute", bottom: "10px", right: "10px" }}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation(); // Prevents navigation when clicking on the icon
                  handleAddToCart(combo);
                }}
              >
                {callingPage === "Admin" ? <AddIcon /> : <ShoppingCartIcon />}
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ComboOffer;
