import React, { useState } from "react";
import { boxDetails } from "../../config/constantselections";
import {
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Typography,
  Box,
} from "@mui/material";
import TextInput from "./../../utility-v1/TextInput";
import SelectBox from "./../../utility-v1/SelectBox";
import { IMAGE_BASE_URL } from "../../config/constants";
import { useImageCache } from "../../App"; // Import the custom hook

const ItemSelector = ({ items, selectedItems, onSelectItem }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [boxNoFilter, setBoxNoFilter] = useState("");

  const imageCache = useImageCache();

  // Handle search term change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle box number filter change
  const handleBoxNoFilterChange = (e) => {
    setBoxNoFilter(e.target.value);
  };

  // Handle item selection
  const handleItemClick = (item) => {
    onSelectItem(item);
  };

  // Filter items based on search term and box number
  const filteredItems = items.filter(
    (item) =>
      item.label.toString().toLowerCase().includes(searchTerm.toLowerCase()) &&
      (boxNoFilter === "" || item.boxno === parseInt(boxNoFilter, 10))
  );

  return (
    <>
      {/* Search Box */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row", // Align items in a row
          gap: 2, // Space between components
          alignItems: "center", // Align items vertically in the center
          padding: 2, // Add padding around the container
        }}
      >
        <TextInput
          label="Item Code"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ flex: 1 }} // Make it flexible to occupy available space
        />

        <SelectBox
          label="Box No"
          value={boxNoFilter}
          onChange={handleBoxNoFilterChange}
          options={boxDetails}
          sx={{ flex: 1 }} // Make it flexible to occupy available space
        />
      </Box>

      {/* Items Display */}
      <Grid
        container
        direction="column"
        sx={{ height: { xs: 375, md: 350 }, width: "100%" }}
      >
        <Box
          sx={{
            display: "flex", // Display items in a row
            flexDirection: "row", // Row layout
            gap: 2, // Space between items
            overflowX: "auto", // Enable horizontal scroll
            maxHeight: "500px", // Limit height if needed
            padding: 2, // Add padding around the scroll area
          }}
        >
          {filteredItems.map((item) => (
            <Card
              key={item.id}
              sx={{
                width: 200, // Fixed width for cards
                border: selectedItems.includes(item.id)
                  ? "2px solid blue"
                  : "1px solid gray",
                cursor: "pointer",
              }}
              onClick={() => handleItemClick(item)}
            >
              <CardActionArea>
                {item.image && (
                  <Box sx={{ position: "relative" }}>
                    <CardMedia
                      component="img"
                      image={
                        imageCache.get(item.image) ||
                        IMAGE_BASE_URL + item.image
                      }
                      alt={`Item ${item.id}`}
                      sx={{
                        height: { xs: 300, md: 250 }, // Adjust height based on screen size
                        transition: "transform 0.3s ease-in-out", // Smooth transition for hover effect
                        cursor: "pointer", // Pointer cursor on hover
                        "&:hover": {
                          transform: "scale(1.1)", // Zoom in effect
                        },
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        width: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.8)", // Black with transparency
                        color: "white",
                        textAlign: "center",
                        padding: "4px 0",
                      }}
                    >
                      <Typography variant="body2">{item.label}</Typography>
                    </Box>
                  </Box>
                )}
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </Grid>
    </>
  );
};

export default ItemSelector;
