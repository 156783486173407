import React, { useContext } from "react";
import { Box, Grid, Typography } from "@mui/material";
import OrderSummaryItem from "./OrderSummaryItem"; // Import the new component
import { FONT_FAMILY } from "../../config/themeConstants"; // Import FONT_FAMILY
import { IMAGE_BASE_URL } from "../../config/constants"; // Import API_BASE_URL
const OrderSummeryShort = ({ cartItems, comboCartObject }) => {
  let index = 0;

  return (
    <Grid id="ordersshort">
      {Object.entries(cartItems).map(([inventoryid, item]) => (
        <Box sx={{ mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            {/* First Column: Combo Name and Item Labels */}
            <Grid item xs={4}>
              <Typography
                sx={{ fontFamily: FONT_FAMILY, fontSize: "15px", mb: 0 }}
              >
                {++index} {item.label}
              </Typography>
              <Typography
                sx={{ fontFamily: FONT_FAMILY, fontSize: "14px", ml: 0 }}
              >
                {item.quantity}
              </Typography>
            </Grid>

            {/* Second Column: Image */}
            <Grid item xs={6}>
              <Box
                component="img"
                src={IMAGE_BASE_URL + item.image}
                alt={item.label}
                sx={{
                  width: "75px", // Full width of the container
                  height: "75px", // Full height of the container
                  objectFit: "cover",
                }}
              />
            </Grid>

            {/* Third Column: Total Price */}
            <Grid item xs={2} sx={{ textAlign: "right" }}>
              <Typography
                sx={{
                  fontFamily: FONT_FAMILY,
                  fontSize: "14px",
                  color: "black",
                }}
              >
                Total: ₹
                {(parseFloat(item.price) * parseFloat(item.quantity)).toFixed(
                  2
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      ))}
      {Object.keys(comboCartObject).length > 0 &&
        Object.entries(comboCartObject).map(([comboId, combo]) => (
          <Box key={comboId} sx={{ mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
              {/* First Column: Combo Name and Item Labels */}
              <Grid item xs={4}>
                <Typography
                  sx={{ fontFamily: FONT_FAMILY, fontSize: "15px", mb: 0 }}
                >
                  {++index} {combo.comboname}
                </Typography>
                {combo.items.map((item) => (
                  <Typography
                    key={item.inventoryid}
                    sx={{ fontFamily: FONT_FAMILY, fontSize: "14px", ml: 0 }}
                  >
                    {item.label}
                  </Typography>
                ))}
              </Grid>

              {/* Second Column: Image */}
              <Grid item xs={6}>
                {combo.items.map((item) => (
                  <Box
                    component="img"
                    src={IMAGE_BASE_URL + item.image}
                    alt={item.label}
                    sx={{
                      mr: "5px",
                      width: "75px", // Full width of the container
                      height: "75px", // Full height of the container
                      objectFit: "cover",
                    }}
                  />
                ))}
              </Grid>

              {/* Third Column: Total Price */}
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography
                  sx={{
                    fontFamily: FONT_FAMILY,
                    fontSize: "14px",
                    color: "black",
                  }}
                >
                  Total: ₹{parseFloat(combo.price).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ))}
    </Grid>
  );
};

export default OrderSummeryShort;
