import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL, IMAGE_BASE_URL } from "../../config/constants";
import { Grid, Typography, Button, CircularProgress, Box } from "@mui/material";
import { GlobalContext } from "./../../GlobalProvider";
import QuantitySelector from "./../../utility-v1/QuantitySelector";
import {
  subcategoryOptionsDefault,
  subcategoryOptionsBangles,
} from "../../config/constantselections";
import { useToast } from "./../../utility-v1/ToastProvider";

const ProductDetails = () => {
  const { handleShowToast } = useToast();
  const { id, category } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { globalVariable, updateGlobalVariable } = useContext(GlobalContext);
  const [quantity, setQuantity] = useState(1);
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/itemview/${id}`);
        setProduct(response.data[0]);
        setLoading(false);

        // Choose subcategory options based on id
        const subCategoryOptions =
          category === "BB"
            ? subcategoryOptionsBangles
            : subcategoryOptionsDefault;
        console.error("subCategoryOptions---", subCategoryOptions);

        // Create subCategories array with additional properties
        const subCategoriesArray = response.data
          .map((item) => {
            const matchedColor = subCategoryOptions.find(
              (option) => option.value === item.subcategory
            );
            if (matchedColor) {
              return {
                ...matchedColor,
                subProduct: item,
              };
            }
            return null;
          })
          .filter(Boolean); // Remove nulls

        setSubCategories(subCategoriesArray);
      } catch (error) {
        console.error("Error fetching product details", error);
        setLoading(false);
      }
    };
    fetchProductDetails();
  }, [id]);

  const handleColorSelect = (subProduct) => {
    setProduct(subProduct);
  };

  const addToCart = () => {
    if (product) {
      const storedCart = JSON.parse(localStorage.getItem("cartItems")) || {};

      if (storedCart[product.inventoryid] && (storedCart[product.inventoryid].quantity + 1 > product.difference)) {
        handleShowToast("Item exceeded stock quantity", "warning")
        return; // Prevent further execution
      }

      if (storedCart[product.inventoryid]) {
        storedCart[product.inventoryid].quantity += quantity;
      } else {
        storedCart[product.inventoryid] = {
          image: product.image,
          label: product.label,
          price: product.sellingprice,
          quantity: quantity,
          left: 0
        };
      }
   
      try {
         console.error("Error setting localStorage:", storedCart);
        localStorage.setItem("cartItems", JSON.stringify(storedCart));
      } catch (error) {
        console.error("Error setting localStorage:", error);
      }
      handleShowToast("Item added to cart", "success")
    }
  };

  const handleQuantityChange = (newQuantity) => {
    setQuantity(newQuantity);
  };

  const buyNowItem = () => {
    if (product) {
      const storedCart = {};
      storedCart[id] = {
        image: product.image,
        label: product.label,
        price: product.sellingprice,
        quantity: quantity,
      };

      try {
        updateGlobalVariable("cartItems", storedCart);
        updateGlobalVariable("comboCart", {});
        navigate(`/checkout?buynow=true`);
      } catch (error) {
        console.error("Error updating globalVariable:", error);
      }
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Safely parse the selling price and calculate original price
  const sellingPrice = parseFloat(product.sellingprice) || 0;
  const discount = parseInt(product.discount) || 0;
  const originalPrice = sellingPrice * (1 + discount / 100);

  return (
    <Box sx={{ padding: 2 }}>
      {product ? (
        <>
          <Typography
            variant="h5"
            sx={{ mb: "10px", textAlign: { xs: "left", md: "left" } }}
          >
            {product.label}
          </Typography>
          <Grid container spacing={0}>
            {/* Product Image */}
            <Grid item xs={12} md={6} sx={{ textAlign: "left" }}>
              <img
                src={IMAGE_BASE_URL + product.image}
                alt={product.label}
                style={{
                  width: "90%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Grid>

            {product.difference === 0 && (<Grid item xs={12} md={6}>
              <Box
                sx={{
                  mt: 4,
                  p: 2,
                  backgroundColor: "#f8d7da", // Light red background
                  borderRadius: "8px",
                  border: "1px solid #f5c6cb", // Border matching the background theme
                  color: "#721c24", // Dark red text
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  The item you are looking for is currently out of stock.
                </Typography>
              </Box>
            </Grid>)}

            {/* Product Details */}
            {product.difference !== 0 && (
              <Grid item xs={12} md={6}>
                {/* Price Section */}
                <Box
                  sx={{
                    display: "flex",
                    mt: "10px",
                    alignItems: "center",
                    gap: 2,
                    flexWrap: "wrap",
                  }}
                >
                  {/* Original Price (Striked through if there's a discount) */}
                  {discount > 0 && (
                    <Typography
                      variant="h6"
                      sx={{
                        textDecoration: "line-through",
                        color: "gray",
                      }}
                    >
                      ₹{originalPrice.toFixed(2)}
                    </Typography>
                  )}

                  {/* Discount Badge */}
                  {discount > 0 && (
                    <Box
                      sx={{
                        backgroundColor: "#f50057",
                        color: "white",
                        borderRadius: "12px",
                        padding: "4px 8px",
                        fontSize: "12px",
                      }}
                    >
                      {discount}% OFF
                    </Box>
                  )}

                  {/* Selling Price */}
                  <Typography variant="h5" sx={{ color: "teal" }}>
                    ₹{sellingPrice.toFixed(2)}
                  </Typography>
                </Box>

                {false && subCategories.length > 0 && (
                  <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
                    <Typography variant="subtitle1">Colors:</Typography>
                    {subCategories.map((color) => (
                      <Button
                        key={color.value}
                        variant="contained"
                        onClick={() => handleColorSelect(color.subProduct)} // Pass subProduct directly
                        sx={{
                          backgroundColor:
                            product.category === "BB"
                              ? "white"
                              : color.label.toLowerCase(),
                          color: product.category === "BB" ? "black" : "white", // Set text color based on id
                          borderRadius: "50%",
                          width: 32,
                          height: 32,
                          minWidth: 32,
                          border:
                            product.category === "BB"
                              ? "1px solid black"
                              : "none", // Add border if id is BB
                          boxShadow:
                            color.subProduct.inventoryid === product.inventoryid
                              ? "0 0 0 3px black"
                              : "none", // Highlight condition
                        }}
                      >
                        {product.category === "BB" ? color.label : ""}
                      </Button>
                    ))}
                  </Box>
                )}

                <Box sx={{ mt: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: "bold", color: "primary.main" }}
                  >
                    {`Only ${product.difference} ${
                      product.difference === 1 ? "item" : "items"
                    } left`}
                  </Typography>
                </Box>

                <Box sx={{ mt: "15px", flexWrap: "wrap" }}>
                  {/* Quantity Selector */}
                  <QuantitySelector
                    initialQuantity={1}
                    minQuantity={1}
                    maxQuantity={product.difference}
                    onQuantityChange={handleQuantityChange}
                  />

                  <Typography
                    sx={{ color: "gray", fontSize: "12px", mt: "10px" }}
                  >
                    Tax included. Shipping calculated at checkout.
                  </Typography>
                </Box>
                {/* Action Buttons */}
                <Box
                  sx={{
                    mt: "10px",
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    gap: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={buyNowItem}
                    sx={{ width: { xs: "100%", md: "auto" } }}
                  >
                    Buy Now
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    fullWidth
                    onClick={addToCart}
                    sx={{ width: { xs: "100%", md: "auto" } }}
                  >
                    Add to cart
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <Typography variant="h6">Product not found.</Typography>
      )}
    </Box>
  );
};

export default ProductDetails;
