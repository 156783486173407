import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography, Box } from "@mui/material";
import CartCard from "./CartCard";
import { GlobalContext } from "./../../GlobalProvider";
import CustomButton from "./../../utility-v1/Button";
import { IMAGE_BASE_URL } from "../../config/constants"; // Import IMAGE_BASE_URL
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon
import IconButton from "@mui/material/IconButton";
import { FONT_FAMILY } from "../../config/themeConstants"; // Import FONT_FAMILY
import { API_BASE_URL } from "../../config/constants";

const Cart = ({ onRemove }) => {

  const [cartItems, setCartItems] = useState([]);
  const [comboCartItems, setComboCartItems] = useState({}); // State for combo cart items as an object
  const [totalCartValue, setTotalCartValue] = useState(0);
  const [totalComboCartValue, setTotalComboCartValue] = useState(0); // State for total combo cart value
  const navigate = useNavigate();
  const { globalVariable, updateGlobalVariable } = useContext(GlobalContext);

  useEffect(() => {
    // Load regular cart items
    updateCartWithDifferences();



    // Load combo cart items from localStorage as an object
    const storedComboCart = JSON.parse(localStorage.getItem("comboCart")) || {};
    console.log("Loaded comboCart from localStorage:", storedComboCart); // Debug: log comboCart
    setComboCartItems(storedComboCart);
    calculateTotalComboCartValue(storedComboCart);
  }, []);

  const updateCartWithDifferences = async () => {
    // Get the stored cart from the global variable or localStorage
    const storedCart =JSON.parse(localStorage.getItem("cartItems")) || {};
        console.log(`Error fetching data fo`+storedCart);
    // Iterate through the keys of the storedCart
    for (const id of Object.keys(storedCart)) {
      try {
        // Fetch data for the current id
        const response = await axios.get(`${API_BASE_URL}/itemview/${id}`);
  
        // Extract the `difference` value from response.data[0]
        const difference = response.data[0]?.difference || 0;
  
        // Update the storedCart object with the `left` property
        storedCart[id].left = difference;
        if (storedCart[id].quantity > difference) {
          storedCart[id].quantity = difference;
        }
      } catch (error) {
        console.error(`Error fetching data for id ${id}:`, error.message);
      }
    }
  
    // Update localStorage and the globalVariable with the modified storedCart
    localStorage.setItem("cartItems", JSON.stringify(storedCart));
    setCartItems(storedCart);
    calculateTotalCartValue(storedCart);
  
    // Optionally update any state or UI with the updated cart
  //  setStoredCart(storedCart);
  };
  

  // Calculate total for regular cart items
  const calculateTotalCartValue = (cart) => {
    let total = 0;
    Object.entries(cart).forEach(([inventoryid, item]) => {
      total += item.price * item.quantity;
    });
    setTotalCartValue(total);
  };

  // Calculate total for combo cart items (now using object)
  const calculateTotalComboCartValue = (comboCart) => {
    let total = 0;
    Object.values(comboCart).forEach((combo) => {
      total += combo.price; // Assuming `combo.price` is already the total for that combo
    });
    setTotalComboCartValue(total);
  };

  const handleRemove = (inventoryid) => {
    const storedCart = JSON.parse(localStorage.getItem("cartItems")) || {};
    delete storedCart[inventoryid];
    setCartItems(storedCart);
    localStorage.setItem("cartItems", JSON.stringify(storedCart));
    updateGlobalVariable("cartItems", storedCart);
    calculateTotalCartValue(storedCart);
    if (onRemove) onRemove();
  };

  const handleRemoveCombo = (comboId) => {
    const storedComboCart = JSON.parse(localStorage.getItem("comboCart")) || {};
    delete storedComboCart[comboId]; // Remove combo by ID

    setComboCartItems(storedComboCart);
    localStorage.setItem("comboCart", JSON.stringify(storedComboCart));
    calculateTotalComboCartValue(storedComboCart);
    console.log("Removed combo:", storedComboCart);
  };

  const updateParent = (inventoryid, newQuantity) => {
    const storedCart = JSON.parse(localStorage.getItem("cartItems")) || {};
    if (storedCart[inventoryid]) {
      if (newQuantity > storedCart[inventoryid].left) {
        // Show an alert or handle the case when newQuantity exceeds difference
        alert(`Cannot add more than ${storedCart[inventoryid].left} items to the cart.`);
      } else {
        // Update the quantity in storedCart if it is within the limit
        storedCart[inventoryid].quantity = newQuantity;
        // Save the updated cart back to localStorage
        localStorage.setItem("cartItems", JSON.stringify(storedCart));
      }
    }
    
    setCartItems(storedCart);
    localStorage.setItem("cartItems", JSON.stringify(storedCart));
    updateGlobalVariable("cartItems", storedCart);
    calculateTotalCartValue(storedCart);
  };

  const handleCheckout = () => {
    navigate("/checkout");
  };

  return (
    <>
    <Container
      sx={{
        maxWidth: {
          xs: "100%", // 100% width for extra-small (mobile) devices
          lg: "800px", // 600px width for larger devices
        },
      }}
    >
      {/* Display regular cart items */}
      {Object.keys(cartItems).length !== 0 &&
        Object.entries(cartItems).map(([inventoryid, item]) => (
          <CartCard
            key={inventoryid} // Add a unique key for each item in the list
            image={item.image}
            label={item.label}
            price={item.price}
            quantity={item.quantity}
            itemLeft={item.left}
            handleRemove={() => handleRemove(inventoryid)}
            updateParent={(newQuantity) =>
              updateParent(inventoryid, newQuantity)
            }
          />
        ))}

      {/* Display combo cart items */}
      {Object.keys(comboCartItems).length > 0 && (
        <Box sx={{ mt: 4, position: "relative" }}>
          <Grid container spacing={2}>
            {Object.entries(comboCartItems).map(([comboId, combo]) => (
              <Grid item xs={12} key={comboId}>
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    p: 1,
                    mb: 1,
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  {/* Combo Items */}
                  <Grid container spacing={2}>
                    {combo.items.map((item) => (
                      <Grid item xs={4} sm={4} md={4} key={item.inventoryid}>
                        {" "}
                        {/* 3 columns across all screen sizes */}
                        <Box
                          sx={{
                            position: "relative",
                            width: "100%", // Full width within the grid item
                            height: { xs: "110px", lg: "180px" }, // Responsive height
                            borderRadius: "8px",
                            overflow: "hidden", // Clip content to the rounded corners
                          }}
                        >
                          <Box
                            component="img"
                            src={IMAGE_BASE_URL + item.image}
                            alt={item.label}
                            sx={{
                              width: "100%", // Full width of the container
                              height: "100%", // Full height of the container
                              objectFit: "cover",
                            }}
                          />
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              width: "100%",
                              bgcolor: "rgba(0, 0, 0, 0.6)", // Semi-transparent dark background
                              color: "white",
                              textAlign: "center",
                              py: 0.5, // Padding for the label band
                            }}
                          >
                            <Typography
                              sx={{ fontFamily: FONT_FAMILY, fontSize: "12px" }}
                            >
                              {item.label}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    ))}
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      sx={{
                        position: "relative",
                      }}
                    >
                      <Typography
                        sx={{ fontFamily: FONT_FAMILY, fontSize: "15px" }}
                      >
                        {combo.comboname}
                      </Typography>
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: "0px",
                          right: "0px",
                          fontFamily: FONT_FAMILY,
                          fontSize: "14px",
                        }}
                      >
                        Total: ₹{parseFloat(combo.price).toFixed(2)}
                      </Typography>
                      {/* Remove Combo Button */}
                      <Box>
                        <IconButton
                          onClick={() => handleRemoveCombo(comboId)}
                          sx={{
                            position: "absolute",
                            top: "5px",
                            right: "-5px",
                            color: "#000", // Use a color that contrasts with the background
                          }}
                        >
                          <DeleteIcon
                            sx={{
                              fontSize: { xs: 17, sm: 20, md: 20 }, // Responsive font size for different devices
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      {/* Total and Checkout Buttons */}
      {(Object.keys(cartItems).length !== 0 || Object.keys(comboCartItems).length > 0) && ( <Box
        sx={{
          mt: 2,
          borderTop: "1px solid", // Set the top border width
          borderColor: "primary.main", // Choose the color (MUI theme color or hex)
        }}
      >
        {/* Total Cart Value */}
        <Typography
          sx={{
            fontSize: "15px",
            fontWeight: 600,
            mt: 2,
            mb: 2,
            textAlign: { xs: "right" }, // Align text to center for small devices, right for large
            width: "100%",
          }}
        >
          Total Cart Value: ₹{(totalCartValue + totalComboCartValue).toFixed(2)}
        </Typography>

        {/* Buttons section */}
        <Grid
          container
          spacing={2}
          justifyContent="space-between" // Buttons will have space between on large devices
          sx={{
            flexDirection: { xs: "column", md: "row" }, // Stack vertically on small devices, side by side on large
          }}
        >
          {/* Proceed to Checkout Button */}
          <Grid item xs={12}>
            {(Object.keys(cartItems).length > 0 ||
              Object.keys(comboCartItems).length > 0) && (
              <CustomButton
                label="Proceed to Checkout"
                variant="contained"
                color="primary"
                size="large"
                onClick={handleCheckout}
                sx={{
                  width: "100%", // Full width on both small and large devices
                  fontWeight: 600,
                }}
              />
            )}
          </Grid>
          {/* Continue Shopping Button */}
          <Grid item xs={12}>
            <CustomButton
              label="Continue Shopping"
              variant="text"
              color="primary"
              size="large"
              onClick={() => navigate("/list/All")}
              sx={{
                width: "100%", // Full width on both small and large devices
                fontWeight: 600,
              }}
            />
          </Grid>
        </Grid>
      </Box>)}
    </Container>
    {(Object.keys(cartItems).length === 0 && Object.keys(comboCartItems).length === 0) && ( <Box
     sx={{
       display: "flex",
       flexDirection: "column",
       justifyContent: "center",
       alignItems: "center",
       height: "25vh",
       textAlign: "center",
       gap: 2,
       padding: 3,
       border: "1px solid #ddd",
       borderRadius: "8px",
       boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
     }}
   >
     <Typography variant="h6" component="h2" color="textSecondary">
       You don’t have any items in your cart.
     </Typography>
     </Box>)}
</>
  );
};

export default Cart;
