import React, { useState } from "react";
import { Grid, Typography, Button, Collapse, Badge } from "@mui/material";
import IconHyperlink from "./../../utility-v1/IconHyperlink";
import { API_BASE_URL, IMAGE_BASE_URL } from "../../config/constants";

const SalesCardwithCarousel = ({
  items,
  itemsList,
  name,
  buyerdetails,
  totalprice,
  phonenumber,
  editClick,
  deleteClick,
  deleteIcon,
  editIcon,
  shipmentPrice,
  shipmentDate,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [detailsVisible, setDetailsVisible] = useState(false);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };

  const getItemDetails = () => {
    return Object.keys(items)
      .map((itemId) => {
        const item = itemsList.find((item) => item.inventoryid === itemId);
        return item
          ? {
              ...item,
              quantity: items[itemId][0],
              price: items[itemId][1],
              discount: items[itemId][2],
            }
          : null;
      })
      .filter((item) => item !== null);
  };

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handleImageClick = () => {
    setDetailsVisible(!detailsVisible);
  };

  const itemDetails = getItemDetails();
  const totalPages = itemDetails.length;

  return (
    <div className="card small-card">
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Typography variant="h6">
            <strong>{name}</strong>
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconHyperlink
            href="#"
            onClick={editClick}
            icon={editIcon}
            iconSize="small"
          />
        </Grid>
        <Grid item xs={2}>
          <IconHyperlink
            href="#"
            onClick={deleteClick}
            icon={deleteIcon}
            iconSize="small"
          />
        </Grid>
        <Grid item xs={12}>
          {itemDetails.length > 0 && (
            <div onClick={handleImageClick} style={{ cursor: "pointer" }}>
              <img
                src={IMAGE_BASE_URL + itemDetails[currentPage].image}
                alt="Item"
                style={{ width: "100%", height: "auto" }}
              />
              <Collapse in={detailsVisible}>
                <div className="item-details">
                  <Typography>Code: {itemDetails[currentPage].code}</Typography>
                  <Typography>
                    Box #: {itemDetails[currentPage].boxno}
                  </Typography>
                  <Typography>
                    Quantity: {itemDetails[currentPage].quantity}
                  </Typography>
                  <Typography>
                    Price: {formatPrice(itemDetails[currentPage].price)}
                  </Typography>
                  <Typography>
                    Discount: {itemDetails[currentPage].discount}%
                  </Typography>
                </div>
              </Collapse>
            </div>
          )}
        </Grid>

        <Grid item xs={12}>
          <div style={{ textAlign: "center" }}>
            {Array.from({ length: totalPages }).map((_, index) => (
              <Button
                key={index}
                size="small"
                variant={index === currentPage ? "contained" : "outlined"}
                onClick={() => handlePageClick(index)}
                style={{ margin: "0 4px" }}
              >
                {index + 1}
              </Button>
            ))}
          </div>
        </Grid>

        <Grid item xs={12}>
          <ul style={{ listStyle: "none", padding: 0 }}>
            <li>
              <Typography variant="body1">
                Buyer Details{" "}
                <Badge color="primary" badgeContent={buyerdetails}></Badge>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Total Price{" "}
                <Badge
                  color="primary"
                  badgeContent={formatPrice(totalprice)}
                ></Badge>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Phone Number{" "}
                <Badge color="primary" badgeContent={phonenumber}></Badge>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Shipment Price{" "}
                <Badge color="primary" badgeContent={shipmentPrice}></Badge>
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Shipment Date{" "}
                <Badge color="primary" badgeContent={shipmentDate}></Badge>
              </Typography>
            </li>
          </ul>
        </Grid>
      </Grid>
    </div>
  );
};

export default SalesCardwithCarousel;
