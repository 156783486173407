import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Container,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import Close icon
import SearchIcon from "@mui/icons-material/Search";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { IMAGE_BASE_URL } from "../../config/constants";
import SearchWithDropdown from "./../SalesRecordPage/SearchWithDropdown";
import { FONT_FAMILY } from "../../config/themeConstants";

const MenuBar = ({ SearchComponent }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const navigate = useNavigate();

  // Toggle the mobile drawer
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Toggle the search modal
  const handleSearchModalToggle = () => {
    setIsSearchModalOpen(!isSearchModalOpen);
  };

  const menuItems = [
    { text: "Home", href: "/welcome" },
    { text: "All", href: "/list/All" },
    { text: "Necklace", href: "/list/Necklace" },
    { text: "Haarams", href: "/list/Haarams" },
    { text: "AD Stone", href: "/list/Adstone" },
    { text: "Bangles", href: "/list/BB" },
    { text: "Earrings", href: "/list/Earrings" },
    { text: "Combo Offer", href: "/combooffer" },
    { text: "My Cart", href: "/bag" },
  ];

  const menuItemsAdmin = [
    { text: "Sales", href: "/sales" },
    { text: "Shipment", href: "/shipment" },
    { text: "Add Inventory", href: "/inventory" },
    { text: "Search Inventory", href: "/inventory-details" },
    { text: "Reports", href: "/analytics" },
  ];

  // Drawer content for mobile
  const drawer = (
    <div>
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            button
            key={index}
            onClick={() => {
              navigate(item.href); // Use navigate for routing
              setMobileOpen(false); // Close the drawer
            }}
          >
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                sx: {
                  fontFamily: FONT_FAMILY,
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: 500,
                },
              }}
            />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <>
      {/* Top AppBar for desktop and mobile */}
      <AppBar
        position="fixed"
        color="default"
        sx={{
          backgroundColor: "white",
          boxShadow: "none",
          borderBottom: "1px solid #ccc",
          paddingTop:{xs:"10px",lg:"20px"},
          paddingBottom:{xs:"10px",lg:"20px"}
        }}
      >
        <Container maxWidth="lg">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            {/* Title on the left for both mobile and large devices */}
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
                ml: { xs: -2, md: -4 },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Pacifico, cursive", // Apply Pacifico font
                  color: "black",
                  fontSize: "1.75rem",
                }}
              >
                illolam jewels
              </Typography>

              <img
                src={`${IMAGE_BASE_URL}/images/ruby-stone-2765.png`}
                alt="My Image"
                style={{
                  width: "20px",
                  height: "20px",
                  position: "absolute",
                  top: "0px",
                  left: "84px", // Adjust based on where you want the image relative to the text
                  zIndex: 2000,
                }}
              />
            </Box>

            {/* Icons on the right for mobile, with desktop layout unchanged */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                ml: { xs: "auto", md: 0 }, // Align to right on mobile
              }}
            >
              {/* Shopping bag icon */}
              <IconButton
                edge="end"
                color="inherit"
                aria-label="shopping-bag"
                onClick={() => {
                  navigate('/bag'); // Use navigate for routing
                }}
                sx={{
                  display: { xs: "block", sm: "block", md: "none" },
                  mr: 2,
                }} // Adjusted margin-right
              >
                <ShoppingCartIcon sx={{ fontSize: 25 }} />
              </IconButton>

              {/* Search icon */}
              <IconButton
                edge="end"
                color="inherit"
                aria-label="search"
                onClick={handleSearchModalToggle}
                sx={{
                  display: { xs: "block", sm: "block", md: "none" },
                  mr: 2,
                }} // Adjusted margin-left
              >
                <SearchIcon sx={{ fontSize: 25 }} />
              </IconButton>

              {/* Menu icon for mobile */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerToggle}
                sx={{
                  display: { xs: "block", md: "none" }, // Show only on mobile
                  mr: -2, // Margin-left for space between icons
                }}
              >
                <MenuIcon sx={{ fontSize: 35 }} />
              </IconButton>
            </Box>

            {/* Desktop Menu (visible for larger screens) */}
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 2 
              }}
            >
              {menuItems.map((item, index) => (
                <Typography
                  key={index}
                  sx={{
                    cursor: "pointer",
                    fontFamily: FONT_FAMILY,
                    fontSize: "20px",
                    color: "#000",
                    fontWeight: 500
                  }}
                  onClick={() => navigate(item.href)} // Use navigate instead of <a>
                >
                  {item.text}
                </Typography>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      {/* Drawer for mobile */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "80%",
            top: "55px",
            transition: "top 0.3s ease",
            position: "relative",
            zIndex: 1300,
            borderTopRightRadius: "20px",
            borderBottomLeftRadius: "20px",
          },
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          aria-label="close"
          onClick={handleDrawerToggle}
          sx={{
            position: "absolute",
            top: 8,
            right: 20,
            zIndex: 1400,
          }}
        >
          <CloseIcon />
        </IconButton>
        {drawer}
      </Drawer>

      {/* Modal for search component */}
      <Drawer
        anchor="top"
        open={isSearchModalOpen}
        onClose={handleSearchModalToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            top: "0px",
            transition: "top 0.3s ease",
            position: "fixed",
            zIndex: 1300,
          },
        }}
      >
        <Box p={2}>
          <Box sx={{ mt: 0 }}>
            <SearchWithDropdown
              onSelectItem={(item) => {
                navigate(`/list/All/${item.inventoryid}`);
                setIsSearchModalOpen(false);
              }}
            />
          </Box>
        </Box>
      </Drawer>
      <Box
        sx={{
          mt: { xs: "80px",md: "108px"}, // Different mt values based on screen size
        }}
      ></Box>
    </>
  );
};

export default MenuBar;
