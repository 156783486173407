import React, { createContext, useState, useContext } from "react";
import Toast from "./Toast"; // Import your Toast component

// Create the context
const ToastContext = createContext();

// Provider Component
export const ToastProvider = ({ children }) => {
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");

  const handleShowToast = (message, severity = "success") => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
  };

  return (
    <ToastContext.Provider value={{ handleShowToast }}>
      {children}
      <Toast
        open={toastOpen}
        severity={toastSeverity}
        message={toastMessage}
        onClose={() => setToastOpen(false)}
      />
    </ToastContext.Provider>
  );
};

// Custom Hook
export const useToast = () => {
  return useContext(ToastContext);
};
