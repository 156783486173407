import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import { API_BASE_URL } from '../../config/constants';
import CustomDatePicker from './../../utility-v1/CustomDatePicker';

const AnalyticsPage = () => {
    const [sales, setSales] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [profitStartDate, setProfitStartDate] = useState('');
    const [profitEndDate, setProfitEndDate] = useState('');
    const [filteredProfitSales, setFilteredProfitSales] = useState([]);

    let sellingPrice = 0;
    let buyingPrice = 0;
    let totalQuantity = 0;
    let profit = 0;
    let shipmentTotal = 0;
    let boxCountPrice = 0;
    let profitAmount = 0;

    useEffect(() => {
        axios.get(`${API_BASE_URL}/sales`).then(response => {
            setSales(response.data);
            setDefaultDateRanges();
        }).catch(error => {
            console.error('Error fetching sales data:', error);
        });

        axios.get(`${API_BASE_URL}/items`).then(response => {
            setInventory(response.data);
        }).catch(error => {
            console.error('Error fetching inventory data:', error);
        });
    }, []);

    const setDefaultDateRanges = () => {
        const today = new Date();
        const last30Days = new Date(today.setDate(today.getDate() - 30)).toISOString().split('T')[0];
        const todayString = new Date().toISOString().split('T')[0];

        setProfitStartDate(last30Days);
        setProfitEndDate(todayString);
    };

    useEffect(() => {
        if (profitStartDate && profitEndDate) {
            const filtered = sales.filter(sale => {
                const saleDate = moment(sale.sales_date, 'YYYY-MM-DD');
                const startDate = moment(profitStartDate, 'YYYY-MM-DD');
                const endDate = moment(profitEndDate, 'YYYY-MM-DD');

                return saleDate.isBetween(startDate, endDate, null, '[]');
            });
            setFilteredProfitSales(filtered);
        }
    }, [profitStartDate, profitEndDate, sales]);

    const calculateProfitData = () => {
        sellingPrice = 0;
        buyingPrice = 0;
        totalQuantity = 0;
        profit = 0;
        shipmentTotal = 0;
        boxCountPrice = 0;
        profitAmount = 0;

        filteredProfitSales.forEach(sale => {
            Object.keys(sale.items).forEach(itemId => {
                const item = inventory.find(i => i.inventoryid === itemId);
                if (item) {
                    const quantitySold = parseFloat(sale.items[itemId][0]);
                    const sellingPricePerItem = parseFloat(sale.items[itemId][1]);

                    totalQuantity += quantitySold;
                    buyingPrice += item.price * quantitySold;
                }
            });
            shipmentTotal += sale.shipment_price ? parseFloat(sale.shipment_price) : 0;
            sellingPrice += parseFloat(sale.price);
            boxCountPrice += 20; // Assuming a fixed box price
        });
        profitAmount = (sellingPrice - buyingPrice - shipmentTotal - boxCountPrice);
        profit = ((profitAmount / buyingPrice) * 100).toFixed(2);
    };

    calculateProfitData();

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Profit Analytics
            </Typography>

            {/* Date Range Selection */}
            <Grid container spacing={2} sx={{ mb: 2 }}>
                <Grid item xs={12} sm={6}>
                            <CustomDatePicker
                   label="Start Date"
                   value={profitStartDate}
                   onChange={(e) => setProfitStartDate(e.target.value)}
        />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CustomDatePicker
                    label="End Date"
                    value={profitEndDate}
                    onChange={(e) => setProfitEndDate(e.target.value)}
                    />
                </Grid>
            </Grid>

            {/* Profit Table */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Buying Price</TableCell>
                            <TableCell>Selling Price</TableCell>
                            <TableCell>Shipment</TableCell>
                            <TableCell>Packaging</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Profit</TableCell>
                            <TableCell>Profit Percentage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>{buyingPrice}</TableCell>
                            <TableCell>{sellingPrice}</TableCell>
                            <TableCell>{shipmentTotal}</TableCell>
                            <TableCell>{boxCountPrice}</TableCell>
                            <TableCell>{totalQuantity}</TableCell>
                            <TableCell>{profitAmount}</TableCell>
                            <TableCell>{profit}%</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default AnalyticsPage;
