// constants.js

export const itemCategory = [
    { value: '-1', label: 'Select' },
    { value: 'Necklace', label: 'Necklace' },
    { value: 'Haarams', label: 'Haarams' },
    { value: 'Adstone', label: 'AD Stone' },
    { value: 'BB', label: 'Bangles & Bracelets' },
    { value: 'Earrings', label: 'Earrings' }
];

export const subcategoryOptionsDefault = [
    { value: '-1', label: 'Select' },
    { value: '0', label: 'None' },
    { value: '1', label: 'Red' },
    { value: '2', label: 'Green' },
    { value: '3', label: 'White' },
    { value: '4', label: 'Mixed' }
];

export const subcategoryOptionsBangles = [
    { value: '-1', label: 'Select' },
    { value: '0', label: 'None' },
    { value: '1', label: '2.2' },
    { value: '2', label: '2.4' },
    { value: '3', label: '2.6' },
    { value: '4', label: '2.8' }
];

export const itemPriority = [
    { value: '-1', label: 'Select' },
    { value: '1', label: 'Priority-1' },
    { value: '2', label: 'Priority-2' },
    { value: '3', label: 'Priority-3' },
    { value: '4', label: 'Priority-4' },
    { value: '5', label: 'Priority-5' },
    { value: '6', label: 'Priority-6' },
    { value: '7', label: 'Priority-7' },
    { value: '8', label: 'Priority-8' },
    { value: '9', label: 'Priority-9' },
    { value: '10', label: 'Priority-10' }
];

export const itemDiscount = [
    { value: '-1', label: 'Select' },
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
    { value: '20', label: '20' },
    { value: '25', label: '25' },
    { value: '30', label: '30' },
    { value: '40', label: '40' },
    { value: '50', label: '50' },
    { value: '60', label: '60' },
    { value: '70', label: '70' },
    { value: '80', label: '80' }
];

export const webCategory = [
    { value: '-1', label: 'Select' },
      { value: '1', label: 'Flash Sale' },
      { value: '2', label: 'Onam Sale' },
      { value: '3', label: 'Holiday Special' }
  ];

export const webStatus = [
    { value: '-1', label: 'Select' },
    { value: '1', label: 'Public' },
    { value: '2', label: 'Inactive' },
    { value: '3', label: 'Hold' }
];

export const boxDetails = [
    { value: '', label: 'Select' },
    { value: '1000', label: 'Not in box' },
    { value: '1', label: 'Box-1' },
    { value: '2', label: 'Box-2' },
    { value: '3', label: 'Box-3' },
    { value: '4', label: 'Box-4' },
    { value: '5', label: 'Box-5' },
    { value: '6', label: 'Box-6' },
    { value: '7', label: 'Box-7' },
    { value: '8', label: 'Box-8' },
    { value: '9', label: 'Box-9' },
    { value: '10', label: 'Box-10' },
    { value: '11', label: 'Box-11' },
    { value: '12', label: 'Box-12' },
    { value: '13', label: 'Box-13' },
    { value: '14', label: 'Box-14' },
    { value: '15', label: 'Box-15' },
    { value: '16', label: 'Box-16' },
    { value: '17', label: 'Box-17' },
    { value: '18', label: 'Box-18' },
    { value: '19', label: 'Box-19' },
    { value: '20', label: 'Box-20' },
    { value: '21', label: 'Box-21' },
    { value: '22', label: 'Box-22' },
    { value: '23', label: 'Box-23' },
    { value: '24', label: 'Box-24' },
    { value: '25', label: 'Box-25' },
    { value: '26', label: 'Box-26' },
    { value: '27', label: 'Box-27' },
    { value: '28', label: 'Box-28' },
    { value: '29', label: 'Box-29' },
    { value: '30', label: 'Box-30' }
];

export const indianStates = [
    { label: "Kerala", value: "KL" },
    { label: "Tamil Nadu", value: "TN" },
    { label: "Karnataka", value: "KA" },
    { label: "Andhra Pradesh", value: "AP" },
    { label: "Arunachal Pradesh", value: "AR" },
    { label: "Assam", value: "AS" },
    { label: "Bihar", value: "BR" },
    { label: "Chhattisgarh", value: "CG" },
    { label: "Goa", value: "GA" },
    { label: "Gujarat", value: "GJ" },
    { label: "Haryana", value: "HR" },
    { label: "Himachal Pradesh", value: "HP" },
    { label: "Jharkhand", value: "JH" },
    { label: "Madhya Pradesh", value: "MP" },
    { label: "Maharashtra", value: "MH" },
    { label: "Manipur", value: "MN" },
    { label: "Meghalaya", value: "ML" },
    { label: "Mizoram", value: "MZ" },
    { label: "Nagaland", value: "NL" },
    { label: "Odisha", value: "OR" },
    { label: "Punjab", value: "PB" },
    { label: "Rajasthan", value: "RJ" },
    { label: "Sikkim", value: "SK" },
    { label: "Telangana", value: "TG" },
    { label: "Tripura", value: "TR" },
    { label: "Uttar Pradesh", value: "UP" },
    { label: "Uttarakhand", value: "UK" },
    { label: "West Bengal", value: "WB" },
  ];

