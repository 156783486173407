import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Link, Drawer } from "@mui/material";
import CartCard from "./CartCard";
import TextInput from "./../../utility-v1/TextInput";
import { IMAGE_BASE_URL } from "../../config/constants"; // Import API_BASE_URL
import { FONT_FAMILY } from "../../config/themeConstants"; // Import FONT_FAMILY
import OrderSummaryShort from "./../OrderSummary/OrderSummaryShort";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon
const coupons = [
  { name: "DISCOUNT20", operator: "%", value: 20 },
  { name: "FLAT100", operator: "-", value: 100 },
  { name: "OFF50", operator: "%", value: 50 },
  // Add more coupons as needed
];

const OrderSummary = ({
  cartItems,
  setStoredCartItems,
  onRemove,
  removeComboItem,
  setItems,
  setCartPrice,
  action,
  setCoupon,
  coupon,
  comboCart = {},
  deliveryState,
  setAutoDiscountsInParent,
  setExtraDiscountInParent,
  extraDiscountForm,
  setExtraDiscountDescriptionInParent,
  extraDiscountDes,
}) => {
  const [totalCartValue, setTotalCartValue] = useState(0);
  const [subTotalValue, setSubTotalValue] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [autoDiscounts, setAutoDiscounts] = useState({
    itemCountDiscount: false,
    totalPriceDiscount: false,
  });
  const [extraDiscount, setExtraDiscount] = useState(""); // User-entered discount
  const [extraDiscountDescription, setExtraDiscountDescription] = useState("");

  let index = 0;
  // Convert comboCart from an object to an array
  const comboCartObject = Object.values(comboCart);

  useEffect(() => {
    calculateTotalCartValue(cartItems);
  });

  useEffect(() => {
    if (coupon) {
      const foundCoupon = coupons.find((c) => c.name === coupon.toUpperCase());
      if (foundCoupon) {
        setAppliedCoupon(foundCoupon);
      }
    }
    if (setAutoDiscountsInParent) {
      setAutoDiscountsInParent(autoDiscounts); // Pass autoDiscounts to parent
    }
    calculateTotalCartValue(cartItems);
  }, [cartItems, appliedCoupon, extraDiscount, deliveryState]);


  useEffect(() => {
    console.log("extraDiscountDescription" + extraDiscountDes);
    if (extraDiscountForm) {
      setExtraDiscount(extraDiscountForm);
    }
    if (extraDiscountDes) {
      setExtraDiscountDescription(extraDiscountDes);
    }
  }, [extraDiscountForm, extraDiscountDes]);

  const isCartEmpty = (cart) => {
    return Object.keys(cart).length === 0;
  };
  const isComboCartEmpty = (cart) => {
    return Object.keys(cart).length === 0;
  };

  const calculateTotalCartValue = (cart) => {
    let total = 0;
    let discountTotal = 0;
    let totalItems = 0;
    const itemsObject = {};

    Object.entries(cart).forEach(([inventoryid, item]) => {
      total += item.price * item.quantity;
      totalItems += item.quantity;
      itemsObject[inventoryid] = [item.quantity, item.price.toString(), 0];
    });

    Object.values(comboCartObject).forEach((combo) => {
      total += combo.price; // Assuming `combo.price` is already the total for that combo
      itemsObject[combo.comboId] = [1, combo.price.toString(), 1];
    });

    setSubTotalValue(total);

    //Dont Move this below code
 
    //Dont Move this below code  -End
/*
    const newAutoDiscounts = {
      itemCountDiscount: false,
      totalPriceDiscount: false,
    };

    if (total >= 2000) {
      newAutoDiscounts.totalPriceDiscount = true;
      discountTotal = discountTotal + parseInt(total) * 0.05;
    }
    if (totalItems >= 5) {
      newAutoDiscounts.itemCountDiscount = true;
      discountTotal = discountTotal + parseInt(total) * 0.1;
    }
    setAutoDiscounts(newAutoDiscounts);
    
    

    if (appliedCoupon) {
      if (appliedCoupon.operator === "%") {
        discountTotal += parseInt(
          (total * parseInt(appliedCoupon.value)) / 100
        );
      } else if (appliedCoupon.operator === "-") {
        discountTotal += parseInt(appliedCoupon.value);
      }
    }
    */
    if (extraDiscount) {
      discountTotal = discountTotal + parseInt(extraDiscount);
    }
    total = total - discountTotal;

    if (deliveryState !== "KL") {
      total = total + 50;
    }

    setTotalCartValue(total);
    setItems(itemsObject);
    setCartPrice(total);
  };

  const handleExtraDiscountChange = (e) => {
    const value = e.target.value;
    setExtraDiscount(value);
    if (setExtraDiscountInParent) {
      setExtraDiscountInParent(value); // Pass updated value to parent
    }
  };

  const handleExtraDiscountDescription = (e) => {
    const value = e.target.value;
    setExtraDiscountDescription(value);
    if (setExtraDiscountDescriptionInParent) {
      setExtraDiscountDescriptionInParent(value); // Pass updated value to parent
    }
  };

  const updateParent = (inventoryid, newQuantity) => {
    const updatedCart = {
      ...cartItems,
      [inventoryid]: {
        ...cartItems[inventoryid],
        quantity: newQuantity,
      },
    };
    setStoredCartItems && setStoredCartItems(updatedCart);
    calculateTotalCartValue(updatedCart);
  };

  const handleCouponChange = (e) => {
    const value = e.target.value;
    setCoupon(value);

    const foundCoupon = coupons.find(
      (coupon) => coupon.name === value.toUpperCase()
    );
    if (foundCoupon) {
      setAppliedCoupon(foundCoupon);
    } else {
      setAppliedCoupon(null);
    }
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  return (
    <Box>
      {action !== "Sales" && (
        <OrderSummaryShort
          cartItems={cartItems}
          comboCartObject={comboCartObject}
        />
      )}

      <Grid id="orders">
        {action === "Sales" &&
          Object.entries(cartItems).map(([inventoryid, item]) => (
            <CartCard
              key={inventoryid}
              image={item.image}
              label={item.label}
              price={item.price}
              boxno={item.boxno}
              quantity={item.quantity}
              handleRemove={() => onRemove(inventoryid)}
              updateParent={(newQuantity) =>
                updateParent(inventoryid, newQuantity)
              }
              callingPage={action}
            />
          ))}
        {/* Combo Cart Items Section */}
        {action === "Sales" && Object.keys(comboCartObject).length > 0 && (
          <Box sx={{ mt: 4, position: "relative" }}>
            <Grid container spacing={2}>
              {Object.entries(comboCartObject).map(([comboId, combo]) => (
                <Grid item xs={12} key={comboId}>
                  <Box
                    sx={{
                      border: "1px solid #ccc",
                      borderRadius: "10px",
                      p: 1,
                      mb: 1,
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {/* Combo Items */}
                    <Grid container spacing={2}>
                      {combo.items.map((item) => (
                        <Grid item xs={4} sm={4} md={4} key={item.inventoryid}>
                          {" "}
                          {/* 3 columns across all screen sizes */}
                          <Box
                            sx={{
                              position: "relative",
                              width: "100%", // Full width within the grid item
                              height: { xs: "110px", lg: "180px" }, // Responsive height
                              borderRadius: "8px",
                              overflow: "hidden", // Clip content to the rounded corners
                            }}
                          >
                            <Box
                              component="img"
                              src={IMAGE_BASE_URL + item.image}
                              alt={item.label}
                              sx={{
                                width: "100%", // Full width of the container
                                height: "100%", // Full height of the container
                                objectFit: "cover",
                              }}
                            />
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                width: "100%",
                                bgcolor: "rgba(0, 0, 0, 0.6)", // Semi-transparent dark background
                                color: "white",
                                textAlign: "center",
                                py: 0.5, // Padding for the label band
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: FONT_FAMILY,
                                  fontSize: "12px",
                                }}
                              >
                                {item.label}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                      <Grid
                        item
                        xs={4}
                        sm={4}
                        md={4}
                        sx={{
                          position: "relative",
                        }}
                      >
                        <Typography
                          sx={{ fontFamily: FONT_FAMILY, fontSize: "15px" }}
                        >
                          {combo.comboname}
                        </Typography>
                        <Typography
                          sx={{
                            position: "absolute",
                            bottom: "0px",
                            right: "0px",
                            fontFamily: FONT_FAMILY,
                            fontSize: "14px",
                          }}
                        >
                          Total: ₹{parseFloat(combo.price).toFixed(2)}
                        </Typography>
                        {/* Remove Combo Button */}
                        {/* Remove Combo Button */}
                        <Box>
                          <IconButton
                            onClick={() => removeComboItem(combo.comboId)}
                            sx={{
                              position: "absolute",
                              top: "5px",
                              right: "-5px",
                              color: "#000", // Use a color that contrasts with the background
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: { xs: 17, sm: 20, md: 20 }, // Responsive font size for different devices
                              }}
                            />
                          </IconButton>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Grid>

      {(!isComboCartEmpty(comboCartObject) || !isCartEmpty(cartItems)) && (
        <>
          {false && <Grid item xs={12} sx={{ textAlign: "center", marginTop: "20px" }}>
            <Link
              component="button"
              sx={{
                fontFamily: FONT_FAMILY,
                fontSize: "14px",
              }}
              onClick={toggleDrawer(true)}
            >
              Apply Coupon
            </Link>
          </Grid>}

          <Grid item xs={12} sx={{ textAlign: "center", marginTop: "20px" }} />

          <Box sx={{ marginTop: 2 }}>
            <Grid container spacing={2} alignItems="center">
              {/* Subtotal */}
              <Grid item xs={9}>
                <Typography
                  sx={{
                    fontFamily: FONT_FAMILY,
                    fontSize: "14px",
                  }}
                  align="left"
                >
                  Sub Total
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontFamily: FONT_FAMILY,
                    fontSize: "14px",
                  }}
                  align="right"
                >
                  ₹{parseFloat(subTotalValue).toFixed(2)}
                </Typography>
              </Grid>

              {/* Applied Coupon */}
              {appliedCoupon && (
                <>
                  <Grid item xs={7}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="left"
                      color="green"
                    >
                      Applied Coupon: {appliedCoupon.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="right"
                      color="green"
                    >
                      {appliedCoupon.operator === "%"
                        ? `${appliedCoupon.value}%`
                        : `Rs ${appliedCoupon.value}`}{" "}
                      off
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="right"
                      color="green"
                    >
                      {appliedCoupon.operator === "%"
                        ? ` ₹ ${(
                            (subTotalValue * appliedCoupon.value) /
                            100
                          ).toFixed(2)}`
                        : ` ₹ ${appliedCoupon.value}`}{" "}
                      {/* Show discounted value */}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sx={{ textAlign: "right", marginTop: 1 }}>
                    <Typography
                      component="button"
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "12px",
                        color: "red",
                        textDecoration: "underline",
                        cursor: "pointer",
                        background: "none",
                        border: "none",
                      }}
                      onClick={() => {
                        setAppliedCoupon(null); // Reset the applied coupon
                        setCoupon(""); // Optionally clear the coupon input
                      }}
                    >
                      Remove Coupon
                    </Typography>
                  </Grid>
                </>
              )}

              {/* Item Count Discount */}
              {autoDiscounts.itemCountDiscount && (
                <>
                  <Grid item xs={7}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="left"
                      color="green"
                    >
                      Additional Discount for 5+ items
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="body2" align="right" color="green">
                      10% off
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="right"
                      color="green"
                    >
                      ₹{parseFloat(subTotalValue * 0.1).toFixed(2)}
                      {/* Show discounted value */}
                    </Typography>
                  </Grid>
                </>
              )}

              {/* Total Price Discount */}
              {autoDiscounts.totalPriceDiscount && (
                <>
                  <Grid item xs={7}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="left"
                      color="green"
                    >
                      Additional Discount for orders above Rs 2000
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="right"
                      color="green"
                    >
                      5% off
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="right"
                      color="green"
                    >
                      ₹{parseFloat(subTotalValue * 0.05).toFixed(2)}
                      {/* Show discounted value */}
                    </Typography>
                  </Grid>
                </>
              )}
              {/* Shipment Value */}
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontFamily: FONT_FAMILY,
                    fontSize: "14px",
                  }}
                  align="left"
                >
                  Shipment
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <Typography
                  sx={{
                    fontFamily: FONT_FAMILY,
                    fontSize: "14px",
                  }}
                  align="right"
                >
                  {deliveryState
                    ? deliveryState === "KL"
                      ? "Free Shipping"
                      : "₹50"
                    : "Shipment calculated after entering delivery details"}
                </Typography>
              </Grid>
              {action === "Sales" && (
                <>
                  <Grid item xs={9}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="left"
                      color="green"
                    >
                      Extra Discount
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <TextInput
                      type="number"
                      variant="outlined"
                      size="small"
                      value={extraDiscount}
                      onChange={handleExtraDiscountChange}
                      sx={{
                        "& .MuiInputBase-root": {
                          fontFamily: FONT_FAMILY,
                          fontSize: "14px",
                        },
                      }}
                      inputProps={{
                        style: { textAlign: "right" },
                      }}
                    />
                  </Grid>
                  {/* Extra Discount Description */}
                  <Grid item xs={12} sx={{ marginTop: 1 }}>
                    <Typography
                      sx={{
                        fontFamily: FONT_FAMILY,
                        fontSize: "14px",
                      }}
                      align="left"
                      color="green"
                    >
                      Extra Discount Description
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      type="text"
                      variant="outlined"
                      size="small"
                      value={extraDiscountDescription}
                      onChange={handleExtraDiscountDescription}
                      placeholder="Enter discount description"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          fontFamily: FONT_FAMILY,
                          fontSize: "14px",
                        },
                      }}
                    />
                  </Grid>
                </>
              )}
              {/* Total Value */}
              <Grid item xs={9}>
                <Typography
                  sx={{
                    fontFamily: FONT_FAMILY,
                    fontSize: "14px",
                  }}
                  align="left"
                >
                  Total Value
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  sx={{
                    fontFamily: FONT_FAMILY,
                    fontSize: "14px",
                  }}
                  align="right"
                >
                  ₹{parseFloat(totalCartValue).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 300, padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Apply Coupon
          </Typography>
          <TextInput
            label="Coupon Code"
            name="coupon"
            value={coupon}
            onChange={handleCouponChange}
            placeholder="Enter coupon name"
            error={false}
            helperText={
              appliedCoupon
                ? `Coupon Applied: ${appliedCoupon.name}`
                : "Enter a valid coupon."
            }
          />
          <Box sx={{ textAlign: "center", marginTop: 3 }}>
            <button
              style={{
                backgroundColor: "#4F98DB",
                color: "#fff",
                padding: "8px 16px",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "14px",
                fontFamily: FONT_FAMILY,
              }}
              onClick={toggleDrawer(false)}
            >
              Close
            </button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default OrderSummary;
