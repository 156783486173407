import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography, Grid, Box } from '@mui/material';
import TextInput from './../../utility-v1/TextInput';
import axios from 'axios';
import { API_BASE_URL, IMAGE_BASE_URL } from '../../config/constants';
import CustomDatePicker from './../../utility-v1/CustomDatePicker';
import dayjs from 'dayjs';  // Import dayjs

const AddComboDialog = ({ open, onClose, selectedItemIds, items }) => {
  const [comboForm, setComboForm] = useState({
    comboname: '',
    price: '',
    expirationdate: dayjs(),  // Ensure dayjs object is initialized
    items: [],
  });
  const [combos, setCombos] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editComboId, setEditComboId] = useState(null);

  useEffect(() => {
    if (open) {
      fetchCombos();
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    if (open && !isEditing && selectedItemIds.length > 0) {
      setComboForm((prevForm) => ({
        ...prevForm,
        items: selectedItemIds,
      }));
    }
  }, [open, selectedItemIds, isEditing]);

  const fetchCombos = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/combo`);
      setCombos(response.data || []);
    } catch (error) {
      console.error('Failed to fetch combos', error);
    }
  };

  const handleComboFormChange = (e) => {
    const { name, value } = e.target;
    setComboForm((prev) => ({ ...prev, [name]: value }));
  };

  
  const handleDateChange = (e) => {
    setComboForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value, // Update the form with the new date
    }));
  };

  const handleAddCombo = async () => {
    try {
    const comboData = {
      ...comboForm,
      expirationdate: dayjs(comboForm.expirationdate).format('YYYY-MM-DD'),  // Format date to YYYY-MM-DD
    };
 

      if (isEditing && editComboId) {
        await axios.put(`${API_BASE_URL}/combo/${editComboId}`, comboData);
      } else {
        await axios.post(`${API_BASE_URL}/combo`, comboData);
      }

      fetchCombos(); // Refresh the combo list after adding/updating
      resetForm(); // Reset the form
      onClose(); // Close the dialog after successful addition
    } catch (error) {
      console.error('Failed to add/update combo', error);
    }
  };

  const handleEditCombo = (combo) => {
    setIsEditing(true);
    setEditComboId(combo.id);
    setComboForm({
      comboname: combo.comboname,
      price: combo.price,
      expirationdate: dayjs(combo.expirationdate),  // Ensure it's a dayjs object
      items: combo.items,
    });
  };

  const resetForm = () => {
    setComboForm({
      comboname: '',
      price: '',
      expirationdate: dayjs(),  // Reset to current date as dayjs object
      items: [],
    });
    setIsEditing(false);
    setEditComboId(null);
  };

  const handleDeleteCombo = async (comboId) => {
    try {
      await axios.delete(`${API_BASE_URL}/combo/${comboId}`);
      fetchCombos(); // Refresh the combo list after deletion
    } catch (error) {
      console.error('Failed to delete combo', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{isEditing ? 'Edit Combo' : 'Add Combo'}</DialogTitle>
      <DialogContent>
        <TextInput
          label="Combo Name"
          name="comboname"
          value={comboForm.comboname}
          onChange={handleComboFormChange}
          fullWidth
          margin="dense"
        />
        <TextInput
          label="Price"
          name="price"
          value={comboForm.price}
          onChange={handleComboFormChange}
          fullWidth
          margin="dense"
        />
        <CustomDatePicker
          label="Expiration Date"
          name="expirationdate"
          value={comboForm.expirationdate}
          onChange={handleDateChange}
          error={false} // Pass true or false based on your validation logic
          helperText="Please select a valid date."  // Customize helper text for errors
        />
        <Typography variant="subtitle1" gutterBottom>
          Selected Items:
        </Typography>
        {comboForm.items.map((id, index) => {
          const selectedItem = items.find(item => item.inventoryid === id);
          return selectedItem ? (
            <Grid item xs={12} key={id}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={1}>
                  <Typography variant="body2">{index + 1}</Typography> {/* Serial Number */}
                </Grid>
                <Grid item xs={2}>
                  <img src={`${IMAGE_BASE_URL}${selectedItem.image}`} alt={selectedItem.label} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{selectedItem.label}</Typography>
                </Grid>
              </Grid>
            </Grid>
          ) : null;
        })}

        {/* List of existing combos */}
        <Box marginTop={4}>
  <Typography variant="h5" gutterBottom>Combo List</Typography>
  <Grid container spacing={2}>
    {combos.map((combo) => (
      <Grid item xs={12} sm={6} key={combo.id}>
        <Box border={1} padding={2} borderRadius={2}>
          <Typography variant="h6">{combo.comboname}</Typography>
          <Typography>Price: Rs {combo.price}</Typography>
          <Typography>Expiration Date: {dayjs(combo.expirationdate).format('DD-MM-YYYY')}</Typography>

          {/* Render item images and labels */}
          <Typography variant="subtitle1">Items:</Typography>
          {combo.items.map((itemId, index) => {
            const item = items.find(i => i.inventoryid === itemId);  // Find the item in the items array
            return item ? (
              <Grid container alignItems="center" spacing={2} key={itemId}>
                <Grid item xs={2}>
                  <img src={`${IMAGE_BASE_URL}${item.image}`} alt={item.label} style={{ width: 50, height: 50 }} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant="body2">{item.label}</Typography>
                </Grid>
              </Grid>
            ) : (
              <Typography key={itemId} variant="body2" color="error">Item not found</Typography>
            );
          })}

          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleEditCombo(combo)}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleDeleteCombo(combo.id)}
          >
            Delete
          </Button>
        </Box>
      </Grid>
    ))}
  </Grid>
</Box>

      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddCombo} variant="contained" color="primary">
          {isEditing ? 'Update Combo' : 'Add Combo'}
        </Button>
        <Button onClick={resetForm} variant="outlined" color="secondary">
          Reset
        </Button>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddComboDialog;
