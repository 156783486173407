import React, { useState } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Construction from "@mui/icons-material/Construction";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import SearchIcon from "@mui/icons-material/Search";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import { FONT_FAMILY } from "../../config/themeConstants"; 
import CustomButton from "./../../utility-v1/Button";

const menuItemsAdmin = [
  { text: "Sales", href: "/sales", icon: <PointOfSaleIcon /> },
  { text: "Shipment", href: "/shipment", icon: <LocalShippingIcon /> },
  { text: "Add Inventory", href: "/inventory", icon: <Inventory2Icon /> },
  { text: "Search Inventory", href: "/inventory-details", icon: <SearchIcon /> },
  { text: "Reports", href: "/analytics", icon: <AssessmentIcon /> },
  { text: "Admin", href: "/admin", icon: <AssessmentIcon /> },
];

const AdminIconMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  const handleOpenMenu = (event) => setAnchorEl(event.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  return (
    <Box sx={{ position: "relative" }}>
      {/* Icon for small devices */}
      <Box
        onClick={handleOpenMenu}
        sx={{
          display: { xs: "flex", md: "none" },
          cursor: "pointer",
          backgroundColor: "white",
          boxShadow: 0,
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          "&:hover": { backgroundColor: "grey.200" },
          ml: 1,
        }}
      >
        <IconButton color="primary" sx={{ padding: 0 }}>
          <Construction />
        </IconButton>
      </Box>

      {/* Button for large devices */}
      <Box
        sx={{
          display: { xs: "none", md: "block" },
          ml: 1
        }}
      >
        <CustomButton
          variant="outlined"
          label="Admin Tools"
          color="primary"
          onClick={handleOpenMenu}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        sx={{ zIndex: 1300 }}
      >
        {menuItemsAdmin.map((item) => (
          <MenuItem
            key={item.text}
            onClick={() => {
              navigate(item.href);
              handleCloseMenu();
            }}
            sx={{ gap: 2 }}
          >
            {item.icon}
            <Typography sx={{ fontFamily: FONT_FAMILY }}>
              {item.text}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AdminIconMenu;
