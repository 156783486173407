import React, { useState } from 'react';
import { AppBar, Tabs, Tab, Box, Typography } from '@mui/material';
import CategoryManagement from './CategoryManagement';
import OrderDetailsManagement from './OrderDetailsManagement';

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const ParentComponent = () => {
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      {/* Customized Tabs */}
      <Box sx={{ bgcolor: '#fff', borderRadius: 2, overflow: 'hidden' }}>
        <AppBar position="static" sx={{ bgcolor: 'transparent', boxShadow: 'none' }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="category management tabs"
            centered
            variant="fullWidth"
            sx={{
              '& .MuiTabs-flexContainer': {
                background: 'linear-gradient(135deg, #3f51b5, #5c6bc0)',
              },
              '& .MuiTab-root': {
                color: '#fff',
                fontWeight: 500,
                textTransform: 'capitalize',
                fontSize: '1rem',
                '&.Mui-selected': {
                  color: '#ffeb3b',
                  fontWeight: 'bold',
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#ffeb3b',
                height: 3,
              },
            }}
          >
            <Tab label="Web Category" />
            <Tab label="Order Details" />
            {/* Add more tabs as needed */}
          </Tabs>
        </AppBar>

        {/* Tab Panels */}
        <TabPanel value={value} index={0}>
          <CategoryManagement />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OrderDetailsManagement />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default ParentComponent;
