import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios'; // Assuming you're using axios for API calls
import { API_BASE_URL} from '../../config/constants';

const OrderDetailsManagement = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [form, setForm] = useState({ label: '', value: '' });
  const [editIndex, setEditIndex] = useState(null);

  // Fetch order details from the server
  useEffect(() => {
    fetchOrderDetails();
  }, []);

  const fetchOrderDetails = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/orderDetails`); // Adjust the API endpoint
      setOrderDetails(response.data);
    } catch (error) {
      console.error('Failed to fetch order details', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleAddOrUpdate = async () => {
    if (form.label === '' || form.value === '') return;

    if (editIndex !== null) {
      // Update logic
      const updatedOrderDetails = [...orderDetails];
      updatedOrderDetails[editIndex] = form;
      setOrderDetails(updatedOrderDetails);
      await axios.put(`${API_BASE_URL}/orderDetails/${updatedOrderDetails[editIndex].id}`, form); // Adjust the API endpoint

    } else {
      // Add logic
      const newOrderDetail = { label: form.label, value: form.value };
      const response = await axios.post(`${API_BASE_URL}/orderDetails`, newOrderDetail); // Adjust the API endpoint
      setOrderDetails((prevDetails) => [...prevDetails, response.data]);
    }

    setForm({ label: '', value: '' });
    setEditIndex(null);
  };

  const handleEdit = (index) => {
    setForm(orderDetails[index]);
    setEditIndex(index);
  };

  const handleDelete = async (index) => {
    const orderDetailToDelete = orderDetails[index];
    await axios.delete(`${API_BASE_URL}/orderDetails/${orderDetailToDelete.id}`); // Adjust the API endpoint

    const updatedOrderDetails = orderDetails.filter((_, i) => i !== index);
    setOrderDetails(updatedOrderDetails);
  };

  return (
    <Box>
      <h2>Order Details Management</h2>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Order Details"
            name="label"
            value={form.label}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Value"
            name="value"
            value={form.value}
            onChange={handleChange}
            variant="outlined"
          />
        </Grid>
      </Grid>
      <Button variant="contained" color="primary" onClick={handleAddOrUpdate}>
        {editIndex !== null ? 'Update Order Details' : 'Add Order Details'}
      </Button>

      {/* Display Table */}
      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order Details</TableCell>
              <TableCell>Value</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderDetails.map((orderDetail, index) => (
              <TableRow key={index}>
                <TableCell>{orderDetail.label}</TableCell>
                <TableCell>{orderDetail.value}</TableCell>
                <TableCell align="right">
                  <IconButton color="primary" onClick={() => handleEdit(index)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderDetailsManagement;
