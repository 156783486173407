import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the Delete icon
import { IMAGE_BASE_URL } from "../../config/constants";
import QuantitySelector from "./../../utility-v1/QuantitySelector";
import { FONT_FAMILY } from "../../config/themeConstants"; // Import FONT_FAMILY

export default function CartCard({
  image,
  label,
  price,
  boxno,
  quantity,
  itemLeft,
  handleRemove,
  updateParent,
  callingPage,
}) {
  const [currentQuantity, setCurrentQuantity] = useState(quantity);
  const [itemTotal, setItemTotal] = useState(parseFloat(price) * quantity);

  useEffect(() => {
    handleQuantityChange(quantity);
  }, [quantity]);

  // Update the total when quantity changes
  const handleQuantityChange = (newQuantity) => {
    setCurrentQuantity(newQuantity);
    const newTotal = parseFloat(price) * newQuantity;
    setItemTotal(newTotal);
    updateParent(newQuantity); // Send updated quantity to parent
  };

  return (
    <Box
      sx={{
        border: "1px solid #e0e0e0",
        borderRadius: "8px",
        padding: 1,
        marginBottom: 1,
        backgroundColor: "#f9f9f9",
        position: "relative", // Added for "Delete" button placement
      }}
    >
      <Grid container spacing={2}>
        {/* Image section with responsive size */}
        <Grid item xs={4} md={3}>
          <CardMedia
            component="img"
            sx={{
              width: { xs: "100px", lg: "130px" }, // Responsive width
              height: { xs: "110px", lg: "180px" }, // Responsive height
              borderRadius: "8px",
              fontFamily: FONT_FAMILY,
              fontSize: "12px",
            }}
            image={IMAGE_BASE_URL + image}
            alt={label}
          />
        </Grid>

        {/* Product details */}
        <Grid item xs={8} md={9} container spacing={1}>
          {/* Label spanning 3 columns */}
          <Grid item xs={12}>
            <Typography sx={{ fontFamily: FONT_FAMILY, fontSize: "15px" }}>
              {label}
            </Typography>
          </Grid>

          {/* Price and Quantity */}
          <Grid item xs={4}>
            <Typography sx={{ fontFamily: FONT_FAMILY, fontSize: "14px" }}>
              ₹{parseFloat(price).toFixed(2)}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", color: "primary.main" }}
            >
              {`Only ${itemLeft} ${itemLeft === 1 ? "item" : "items"} left`}
            </Typography>
            {callingPage === "Sales" ? (
              <Typography
                sx={{ fontFamily: FONT_FAMILY, fontSize: "14px", color: "red" }}
              >
                Box # {boxno}
              </Typography>
            ) : null}
          </Grid>

          <Grid item xs={8}>
            {callingPage === "Checkout1" ? (
              <Typography sx={{ fontFamily: FONT_FAMILY, fontSize: "14px" }}>
                Quantity: {currentQuantity}
              </Typography>
            ) : (
              <QuantitySelector
                initialQuantity={parseInt(currentQuantity)}
                minQuantity={1}
                maxQuantity={itemLeft}
                onQuantityChange={handleQuantityChange}
              />
            )}
          </Grid>

          {/* Total Price */}
          <Grid item xs={12} sx={{ textAlign: { xs: "right", sm: "right" } }}>
            <Typography sx={{ fontFamily: FONT_FAMILY, fontSize: "14px" }}>
              Total: ₹{itemTotal.toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {/* Delete Icon Button - Absolutely positioned */}
      {callingPage !== "Checkout" && (
        <IconButton
          onClick={handleRemove}
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
            color: "#000", // Use a color that contrasts with the background
          }}
        >
          <DeleteIcon
            sx={{
              fontSize: { xs: 17, sm: 20, md: 20 }, // Responsive font size for different devices
            }}
          />
        </IconButton>
      )}
    </Box>
  );
}
