import React, { useState, useEffect } from "react";
import axios from "axios";

import { Box, Pagination } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  InputAdornment,
  IconButton,
  Typography,
  Skeleton,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CalculateRoundedIcon from "@mui/icons-material/CalculateRounded";

import TextInput from "./../../utility-v1/TextInput";
import SelectBox from "./../../utility-v1/SelectBox";
import CustomDatePicker from "./../../utility-v1/CustomDatePicker";
import CustomSwitch from "./../../utility-v1/CustomSwitch";
import CustomButton from "./../../utility-v1/Button";
import ModalBox from "./../../utility-v1/ModalBox";
import ImageUpload from "./../../utility-v1/ImageUpload"; // Import the ImageUpload component
import CustomDrawer from "./../../utility-v1/CustomDrawer";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import SellingPriceCalculator from "./SellingPriceCalculator";
import ItemCard from "./ItemCard";
import Header from "./../HeaderComponent/Header";
import {
  API_BASE_URL,
  IMAGE_BASE_URL,
  APPIMAGE_BASE_URL,
} from "../../config/constants";
import ImageSelection from "./../ImageSelection/ImageSelection";
import {
  itemCategory,
  subcategoryOptionsDefault,
  subcategoryOptionsBangles,
  boxDetails,
} from "../../config/constantselections";

// Extend dayjs with the localizedFormat plugin
dayjs.extend(localizedFormat);

export default function InventoryPage() {
  const GridColSpacing = { xs: 6, md: 4 };

  const [subcategoryOptions, setSubcategoryOptions] = useState(
    subcategoryOptionsDefault
  );
  const [items, setItems] = useState([]);
  const [orderDetails, setOrderDetails] = useState([]);
  const [parentOptions, setParentOptions] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [boxNoFilter, setBoxNoFilter] = useState("");

  const [page, setPage] = useState(1); // For pagination
  const itemsPerPage = 12; // Number of items to display per page

  const [
    openSellingPriceCalculatorModal,
    setOpenSellingPriceCalculatorModal,
  ] = useState(false);
  const handleOpen = () => setOpenSellingPriceCalculatorModal(true);
  const handleClose = () => setOpenSellingPriceCalculatorModal(false);
  const [priceAdjustmentModal, setPriceAdjustmentModal] = useState();
  const [file, setFile] = useState(null);
  const [itemCode, setItemCode] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showImageSelection, setShowImageSelection] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const [errors, setErrors] = useState({
    category: false,
    subcategory: false,
    label: false,
    price: false,
    sellingprice: false,
    quantity: false,
    boxno: false,
    file: false,
    orderdetails: false,
  });
  const resetErrors = () => {
    const newErrors = {
      category: false,
      subcategory: false,
      label: false,
      price: false,
      sellingprice: false,
      quantity: false,
      boxno: false,
      file: false,
      orderdetails: false,
    };

    setErrors(newErrors);
  };

  const validateForm = () => {
    const newErrors = {
      category: form.category === "-1",
      subcategory: form.subcategory === "-1",
      label: form.label === "",
      price: form.price === "",
      sellingprice: form.sellingprice === "",
      quantity: form.quantity === "",
      file: !file && !form.image,
      boxno: form.boxno === "-1",
      orderdetails: form.orderdetails === "-1",
    };

    setErrors(newErrors);
    return !Object.values(newErrors).includes(true); // Returns true if no errors
  };

  const openPriceAdjustmentModal = () => {
    const price = parseFloat(form.price);
    setPriceAdjustmentModal({
      price: price,
      adjustedPrice: price,
      calculations: [`Starting Price: ${price.toFixed(2)}`],
      checkboxChecked: false,
    });
    handleOpen();
  };

  const handleSetItemCode = (code, url) => {
    setItemCode(code);
    setImageUrl(url);
  };

  const [form, setForm] = useState({
    code: "",
    category: "-1",
    sellingprice: "",
    subcategory: "-1",
    price: "",
    quantity: "",
    parent: "1",
    image: "",
    boxno: "-1",
    orderdetails: "-1",
    purchaseDate: dayjs(),
    label: "",
  });

  const fetchData = async () => {
    try {
      const itemRespons2 = await axios.get(`${API_BASE_URL}/orderdetails`);

      // Set the first option as { value: '-1', label: 'Select' } and then append the fetched data
      setOrderDetails([
        { value: "-1", label: "Select" },
        ...itemRespons2.data.map((item) => ({
          value: item.value, // Assuming your data has `value`
          label: item.label, // Assuming your data has `label`
        })),
      ]);

      const itemResponse = await axios.get(`${API_BASE_URL}/items`);
      setItems(itemResponse.data);

      const parentItems = itemResponse.data.map((item) => ({
        value: item.inventoryid,
        label: item.label,
      }));
      setParentOptions([
        { value: "1", label: "Not a sub item." },
        ...parentItems,
      ]);
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (errors[name]) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    }
    console.log("category--", value);
    if (name === "category") {
      if (value === "BB") {
        setSubcategoryOptions(subcategoryOptionsBangles);
      } else {
        setSubcategoryOptions(subcategoryOptionsDefault);
      }
      setForm({ ...form, [name]: value, subcategory: "0" });
    } else {
      setForm({ ...form, [name]: type === "checkbox" ? checked : value });
    }
  };

  const handleParentChange = (e) => {
    const selectedValue = e.target.value;
    setForm((prevForm) => ({
      ...prevForm,
      parent: selectedValue,
    }));
  
    const selectedItem = items.find(
      (item) => item.inventoryid.toString() === selectedValue
    );
    if (selectedItem) {
      setForm({
        ...form,
        code: selectedItem.code,
        category: selectedItem.category,
        sellingprice: selectedItem.sellingprice ? selectedItem.sellingprice.toString() : "",
        price: selectedItem.price ? selectedItem.price.toString() : "",
        label: selectedItem.label || "",
      });
    }
  };
  

  const handleAdd = async () => {
    if (!validateForm()) {
      return;
    }

    let imagePath = form.image;
    let code = form.code;

    if (!imagePath && file) {
      const uploadedFilePath = await uploadImage();

      if (!uploadedFilePath) {
        return;
      }

      imagePath = "/images/" + uploadedFilePath;
      code = uploadedFilePath.split(".").shift();
    }

    const newItem = {
      ...form,
      inventoryid: Date.now(),
      systemdate: new Date().toISOString(),
      price: parseFloat(form.price),
      sellingprice: parseFloat(form.sellingprice),
      quantity: parseInt(form.quantity, 10),
      boxno: parseInt(form.boxno, 10),
      code: code,
      image: imagePath,
      label: form.label,
    };

    axios
      .post(`${API_BASE_URL}/items`, newItem)
      .then(() => {
        fetchData();
        resetForm();
      })
      .catch((error) => {
        console.error("Failed to add item:", error);
      });
  };

  const resetForm = () => {
    setForm({
      code: "",
      category: "-1",
      subcategory: "-1",
      sellingprice: "",
      price: "",
      quantity: "",
      image: "",
      boxno: "-1",
      parent: "1",
      label: "",
      orderdetails: "-1",
    });
    setFile(null);
    setIsEditing(false);
    setEditId(null);
    setUploadStatus("");
  };

  const handleEdit = (id) => {
    const item = items.find((item) => item.inventoryid === id);
    resetErrors();
    if (item) {
      const subcategoryOptionsToSet =
        item.category === "BB"
          ? subcategoryOptionsBangles
          : subcategoryOptionsDefault;

      setSubcategoryOptions(subcategoryOptionsToSet);
      setForm({
        code: item.code,
        category: item.category,
        subcategory: item.subcategory || "",
        sellingprice: item.sellingprice && item.sellingprice.toString(),
        price: item.price.toString(),
        quantity: item.quantity.toString(),
        image: item.image,
        boxno: item.boxno.toString(),
        parent: item.parent || "1",
        label: item.label,
        orderdetails: item.orderdetails,
      });
      setIsEditing(true);
      setEditId(id);
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(`${API_BASE_URL}/items/${id}`)
      .then(() => {
        fetchData();
        resetForm();
      })
      .catch((error) => {
        console.error("Failed to delete item:", error);
      });
  };

  const handleUpdate = async () => {
    if (!validateForm()) {
      return;
    }

    let imagePath = form.image;
    let code = form.code;

    if (file) {
      const uploadedFilePath = await uploadImage();

      if (!uploadedFilePath) {
        return;
      }

      imagePath = "/images/" + uploadedFilePath;
      code = uploadedFilePath.split(".").shift();
    }

    const updatedItem = {
      ...form,
      price: parseFloat(form.price),
      systemdate: new Date().toISOString(),
      sellingprice: parseFloat(form.sellingprice),
      quantity: parseInt(form.quantity, 10),
      boxno: parseInt(form.boxno, 10),
      code: code,
      image: imagePath,
      label: form.label,
      orderdetails: form.orderdetails,
    };

    try {
      await axios.put(`${API_BASE_URL}/items/${editId}`, updatedItem);
      fetchData();
      resetForm();
    } catch (error) {
      console.error("Failed to update item:", error);
    }
  };

  const uploadImage = async () => {
    if (!file) {
      setUploadStatus("No file selected");
      return null;
    }

    try {
      const formData = new FormData();
      formData.append("image", file);

      const response = await axios.post(`${IMAGE_BASE_URL}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setUploadStatus(`File uploaded successfully: ${response.data.filePath}`);

      return response.data.filePath;
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploadStatus("Error uploading file");
      return null;
    }
  };

  const handleImageSelect = (imagePath) => {
    const imageName = imagePath.split("/").pop().split(".").shift();
    setForm({ ...form, image: `${imagePath}`, code: imageName });
    setFile(null);
    setShowImageSelection(false);
  };

  const toggleImageSelection = () => {
    setShowImageSelection(!showImageSelection);
  };

  const toggleDrawer = (open) => () => {
    setIsDrawerOpen(open);
  };

  const renderDrawerContent = () => (
    <div>
      <ImageUpload
        setItemCode={() => {}}
        file={file}
        setFile={setFile}
        image={form.image}
        handleImageSelect={handleImageSelect}
        selectedImage={selectedImage}
      />
    </div>
  );

  // Reset pagination to the first page when filters or search term are changed
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setPage(1); // Reset to the first page
  };

  const handleTypeFilterChange = (e) => {
    setTypeFilter(e.target.value);
    setPage(1); // Reset to the first page
  };

  const handleBoxNoFilterChange = (e) => {
    setBoxNoFilter(e.target.value);
    setPage(1); // Reset to the first page
  };

  const filteredItems = items.filter((item) => {
    const label = item.label ? item.label.toString() : "";
    const matchesSearch =
      searchTerm === "" ||
      label.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = typeFilter === "" || item.category === typeFilter;
    const matchesBoxNo =
      boxNoFilter === "" || item.boxno === parseInt(boxNoFilter);

    return matchesSearch && matchesType && matchesBoxNo;
  });

  const indexOfLastItem = page * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Header
        mainHeader="Inventory Details"
        subHeader="Add details of your item and upload an image."
      />
      <CustomDrawer
        anchor="left"
        renderContent={renderDrawerContent}
        isOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
      />

      <Grid container spacing={3}>
        <Grid size={GridColSpacing}>
          <TextInput
            label="Item Name"
            name="label"
            value={form.label}
            onChange={handleChange}
            placeholder="Enter your name"
            type="text"
            error={errors.label}
            helperText={errors.label && "Enter item name."}
          />
        </Grid>
        <Grid size={GridColSpacing}>
          <SelectBox
            label="Type"
            value={form.category}
            name="category"
            onChange={handleChange}
            options={itemCategory}
            error={errors.category}
            helperText={"Select type."}
          />
        </Grid>
        <Grid size={GridColSpacing}>
          <SelectBox
            label="Color/Size"
            value={form.subcategory}
            name="subcategory"
            onChange={handleChange}
            options={subcategoryOptions}
            error={errors.subcategory}
            helperText={"Select color/size."}
          />
        </Grid>
        <Grid size={GridColSpacing}>
          <TextInput
            label="Stocked quantity"
            name="quantity"
            value={form.quantity}
            type="number"
            onChange={handleChange}
            placeholder="Enter quantity"
            error={errors.quantity}
            helperText={errors.quantity && "Enter stocked quantity."}
          />
        </Grid>
        <Grid size={GridColSpacing}>
          <TextInput
            label="Purchase price"
            name="price"
            value={form.price}
            type="currency"
            onChange={handleChange}
            placeholder="Enter price"
            error={errors.price}
            helperText={errors.price && "Enter purchase price."}
          />
        </Grid>

        <Grid size={GridColSpacing}>
          <TextInput
            label="Selling Price"
            name="sellingprice"
            value={form.sellingprice}
            onChange={handleChange}
            type="currency"
            placeholder="Enter selling price"
            error={!!errors.sellingprice}
            helperText={errors.sellingprice && "Enter selling price."}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    size="small"
                    color="primary"
                    onClick={openPriceAdjustmentModal}
                    aria-label="add"
                  >
                    <CalculateRoundedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid size={GridColSpacing}>
          <SelectBox
            label="Purchase order"
            value={form.orderdetails}
            name="orderdetails"
            onChange={handleChange}
            options={orderDetails}
            error={errors.orderdetails}
            helperText={"Enter purchase order."}
          />
        </Grid>

        <Grid size={GridColSpacing}>
          <SelectBox
            label="Box no"
            value={form.boxno}
            name="boxno"
            onChange={handleChange}
            options={boxDetails}
            error={errors.boxno}
            helperText={"Enter box no."}
          />
        </Grid>

        <Grid size={{ xs: 12, md: 4 }}>
          <SelectBox
            label="Sub item of below item."
            value={form.parent}
            name="parent"
            onChange={handleParentChange}
            options={parentOptions}
            error={false}
            helperText={"Please enter full name."}
          />
        </Grid>

        <Grid container size={{ xs: 12, md: 12 }}>
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "16px",
              marginTop: "16px",
              position: "relative",
              width: "100%",
            }}
          >
            <IconButton
              onClick={toggleDrawer(true)}
              size="small"
              color="primary"
              sx={{
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: 1,
              }}
            >
              <CameraAltIcon />
            </IconButton>

            <Typography
              variant="caption"
              sx={{
                position: "absolute",
                top: "-10px",
                left: "12px",
                backgroundColor: "white",
                padding: "0 4px",
                color: "rgba(0, 0, 0, 0.6)",
                fontWeight: "400",
                fontSize: "1rem",
                cursor: "pointer",
              }}
              onClick={toggleDrawer(true)}
            >
              Image
            </Typography>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              {file ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Selected"
                  style={{
                    width: "130px",
                    height: "130px",
                    marginTop: "25px",
                    cursor: "pointer",
                  }}
                  onClick={toggleDrawer(true)}
                />
              ) : form.image ? (
                <img
                  src={`${IMAGE_BASE_URL}${form.image}`}
                  alt="Selected"
                  style={{
                    width: "130px",
                    height: "130px",
                    marginTop: "25px",
                    cursor: "pointer",
                  }}
                  onClick={toggleDrawer(true)}
                />
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={130}
                  height={130}
                  onClick={toggleDrawer(true)}
                />
              )}
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "center", marginTop: "16px" }}>
              {errors.file && (
                <Typography color="error">Select image.</Typography>
              )}
            </Grid>
          </Box>
        </Grid>

        {!isEditing && (
          <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
            <CustomButton
              label="Save to inventory"
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleAdd}
            />
          </Grid>
        )}

        {isEditing && (
          <Grid size={{ xs: 12 }} sx={{ textAlign: "center" }}>
            <CustomButton
              label="Edit Item"
              variant="outlined"
              color="primary"
              size="large"
              onClick={handleUpdate}
            />
            &nbsp;&nbsp;&nbsp;
            <CustomButton
              label="Cancel edit"
              variant="outlined"
              color="primary"
              size="large"
              onClick={resetForm}
            />
          </Grid>
        )}
      </Grid>

      <ModalBox
        open={openSellingPriceCalculatorModal}
        handleClose={handleClose}
        direction="up"
      >
        <SellingPriceCalculator
          priceAdjustmentModalOrginal={priceAdjustmentModal}
          setForm={setForm}
          form={form}
          handleClose={handleClose}
        />
      </ModalBox>

      <Grid container spacing={2} marginBottom={2} sx={{ marginTop: 4 }}>
        <Grid item size={{ xs: 12, md: 4 }}>
          <TextInput
            label="Item Code"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item size={{ xs: 12, md: 4 }}>
          <SelectBox
            label="Type"
            value={typeFilter}
            onChange={handleTypeFilterChange}
            options={itemCategory}
          />
        </Grid>
        <Grid item size={{ xs: 12, md: 4 }}>
          <SelectBox
            label="Box No"
            value={boxNoFilter}
            onChange={handleBoxNoFilterChange}
            options={boxDetails}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ marginTop: 4 }}>
        {currentItems.map((item) => (
          <Grid item size={{ xs: 6, md: 2 }} key={item.inventoryid}>
            <ItemCard
              item={item}
              onButtonOneClick={() => handleEdit(item.inventoryid)}
              onButtonTwoClick={() => handleDelete(item.inventoryid)}
            />
          </Grid>
        ))}
      </Grid>

      <Grid container justifyContent="center" sx={{ marginTop: 4 }}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Grid>
    </Box>
  );
}
