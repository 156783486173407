import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { IMAGE_BASE_URL } from "../../config/constants";
import { useImageCache } from "../../App"; // Import the custom hook

const ItemDetailsCard = ({
  title,
  image,
  itemcode,
  quantity,
  boxno,
  price,
  sellingprice,
  balancequantity,
  totalsold,
  addToCart,
  inventoryid,
  isSelected,
  onSelectItem,
}) => {
  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    }).format(price);
  };
  const imageCache = useImageCache();
  const cachedImage = imageCache.get(image) || IMAGE_BASE_URL + image; 

  return (
    <Card variant="outlined" sx={{ maxWidth: 345, margin: "auto" }}>
      {image && (
        <CardMedia
          component="img"
          sx={{
            height: { xs: 300, md: 400 }, // Set height based on screen size
            width: { xs: "100%", md: "100%" }, // Full width
            objectFit: "contain", // Ensure the entire image is visible
            transition: "transform 0.3s ease-in-out", // Smooth transition for hover effect
            cursor: "pointer", // Pointer cursor on hover
            "&:hover": {
              transform: "scale(1.1)", // Zoom in effect
            },
          }}
          image={cachedImage} // Image URL
          title={title} // Image title
        />
      )}
      <CardContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={isSelected}
              onChange={() => onSelectItem(inventoryid)}
              color="primary"
            />
          }
          label="Select"
        />
        <Typography gutterBottom variant="h6" component="div">
          {itemcode}
        </Typography>
        <Box>
          {boxno && (
            <Typography variant="body2" color="textSecondary">
              Box No: {boxno}
            </Typography>
          )}
          {price && (
            <Typography variant="body2" color="textSecondary">
              P.P: {formatPrice(price)}
            </Typography>
          )}
          {sellingprice && (
            <Typography variant="body2" color="textSecondary">
              S.P: {formatPrice(sellingprice)}
            </Typography>
          )}
          {quantity && (
            <Typography variant="body2" color="textSecondary">
              Stocked: {quantity}
            </Typography>
          )}
          {balancequantity && (
            <Typography variant="body2" color="textSecondary">
              Quantity left: {balancequantity}
            </Typography>
          )}
          <Typography variant="body2" color="textSecondary">
            Total Sold: {totalsold}
          </Typography>
        </Box>
        {addToCart && (
          <Box marginTop={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => addToCart(itemcode)}
            >
              Add to Cart
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default ItemDetailsCard;
