import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import CustomButton from './../../utility-v1/Button';
import {  IMAGE_BASE_URL } from '../../config/constants';

const ItemCard = ({  item, description, onButtonOneClick, onButtonTwoClick }) => {
  return (
    <Box sx={{ maxWidth: 250, position: 'relative' }}> {/* Outer Box */}
      
      {/* Card containing the image */}
      <Card sx={{ position: 'relative' }}> {/* Set relative to position children */}
        <Box sx={{ position: 'relative' }}>
          <CardMedia
            sx={{ height: 250 }}
            title={item.label}
            image={IMAGE_BASE_URL + item.image}
          />

          {/* Sold out label in the bottom-left corner of the image */}
          <Box
            sx={{
              position: 'absolute',
              bottom: 8, // Adjust position from the bottom of the image
              left: 8, // Adjust position from the left of the image
              backgroundColor: 'black', // Black background
              color: 'white', // White text color
              borderRadius: '12px', // Rounded edges
              padding: '4px 8px', // Padding inside the box
              fontSize: '12px', // Font size for the text
            }}
          >
           {item.category}
          </Box>
        </Box>

        {/* Icon button in top-right corner */}
        <IconButton
          sx={{
            position: 'absolute',
            top: 8, // Adjust the top value to your preference
            right: 8, // Now using 'right' to place in the top-right corner
            color: 'white', // Ensure the icon color is visible
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: Add background for visibility
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.7)', // Darken on hover
            },
          }}
          onClick={onButtonTwoClick} // Example click handler
        >
          <DeleteIcon sx={{ fontSize: 18 }} /> {/* Adjusted font size to make the icon smaller */}
        </IconButton>

        {/* Button below the text */}
        <Box sx={{ mt: 0 }}>
          <CustomButton
            label={item.label}
            variant="text"
            color="primary"
            size="small"
            onClick={onButtonOneClick}
          />
        </Box>

        {/* Texts with Dividers side by side */}
        <Box sx={{ display: 'flex', alignItems: 'left', mb: 1, justifyContent: 'space-evenly' }}>
          <Typography variant="caption">Rs {item.sellingprice}</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant="caption">{item.quantity}</Typography>
          <Divider orientation="vertical" flexItem />
          <Typography variant="caption">{item.parent}</Typography>
        </Box>
      </Card>
    </Box>
  );
};

export default ItemCard;
