import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import TextInput from "./../../utility-v1/TextInput";
import SelectBox from "./../../utility-v1/SelectBox";
import CustomDatePicker from "./../../utility-v1/CustomDatePicker";
import CustomSwitch from "./../../utility-v1/CustomSwitch";
import CustomButton from "./../../utility-v1/Button";
import OrderSummary from "./OrderSummary";
import { FONT_FAMILY } from "../../config/themeConstants"; // Import FONT_FAMILY
import { GlobalContext } from "./../../GlobalProvider";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { API_BASE_URL } from "../../config/constants";
import DeliveryDetails from "./DeliveryDetails";
import ConfirmationPage from "./ConfirmationPage";

import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Extend dayjs with the localizedFormat plugin
dayjs.extend(localizedFormat);

export default function Checkout() {
  const GridColSpacing = { xs: 12, md: 12 };
  const { globalVariable } = useContext(GlobalContext);
  const [storedCart, setStoredCart] = useState([]);
  const [comboCart, setComboCart] = useState([]);
  const [isEditVisible, setIsEditVisible] = useState(true); // State for Confirm visibility
  const [isPreviewVisible, setIsPreviewVisible] = useState(false); // State for Preview visibility
  const [isConfirmVisible, setIsConfirmVisible] = useState(false); // State for Confirm visibility
  const location = useLocation();
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const queryParams = new URLSearchParams(location.search);
  const isBuyNow = queryParams.get("buynow") === "true";
  const [autoDiscounts, setAutoDiscounts] = useState({
    itemCountDiscount: false,
    totalPriceDiscount: false,
  });
  const [form, setForm] = useState({
    items: {},
    name: "",
    salesDate: dayjs(), // Set to current date initially
    price: "",
    buyerDetails: "",
    phoneNumber: "",
    salesStatus: "SP", // Default status set to "SP"
    systemDate: dayjs(), // Adding system date
    giveAway: false, // Adding give away checkbox state
    pincode: "",
    state: "KL",
    coupon: "",
    email: "",
    additionalDiscount: [],
    shipping: "",
  });
  // Function to log the form's updated items

  useEffect(() => {
    if (isBuyNow) {
      setStoredCart(globalVariable.cartItems);
      setComboCart(globalVariable.comboCart);
    } else {
      // Check and assign empty object if "cartItems" is null
      const cartItems = JSON.parse(localStorage.getItem("cartItems")) || {};
      setStoredCart(cartItems);

      // Check and assign empty object if "comboCart" is null
      const comboCartItems =
        JSON.parse(localStorage.getItem("comboCart")) || {};
      setComboCart(comboCartItems);
    }
  });
  const handleContinueShopping = () => {
    // Navigate to shopping page or perform any other action
    navigate(`/list/All`);
  };
  const [errors, setErrors] = useState({
    name: false,
    buyerDetails: false,
    pincode: false,
    state: false,
    phoneNumber: false,
  });
  const validateForm = () => {
    const newErrors = {
      name: form.name === "",
      buyerDetails: form.buyerDetails === "",
      pincode: form.pincode === "",
      state: form.state === "",
      phoneNumber: form.phoneNumber === "",
    };

    setErrors(newErrors);

    return !Object.values(newErrors).includes(true); // Returns true if no errors
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setForm({ ...form, [name]: type === "checkbox" ? checked : value });
  };

  // Function to handle setting items in the form
  const setItemsInForm = (itemsObject) => {
    setForm({ ...form, items: itemsObject });
  };

  const setCartPriceInForm = (itemsObject) => {
    setForm((form) => ({
      ...form,
      price: itemsObject, // Update form.items with the object from OrderSummary
    }));
  };

  const handleConfirm = () => {
    const systemDate = dayjs().format("YYYY-MM-DD");
    const salesDate = dayjs().format("YYYY-MM-DD");
    const shipmentDate = form.shipmentDate
      ? form.shipmentDate.format("YYYY-MM-DD")
      : null;

    form.additionalDiscount = [];
    autoDiscounts.totalPriceDiscount &&
      form.additionalDiscount.push("TOTALPRICE");
    autoDiscounts.itemCountDiscount &&
      form.additionalDiscount.push("ITEMCOUNT");

      const newSale = {
        ...form,
        salesDate,
        systemDate,
        shipmentDate,
        salesStatus: "SP",
        additionalDiscount: form.additionalDiscount,
        shipping: form.state === "KL" ? 0 : 50, // Set shipping to 50 if state is KL, otherwise 0
      };
      

    axios
      .post(`${API_BASE_URL}/sales`, newSale)
      .then((response) => {
        const salesId = response.data.id; // Assuming the response contains the newly created sales ID

        // Prepare the payload for the /salesrecord/insert endpoint
        const salesRecordData = {
          id: salesId, // The newly created sales ID
          items: form.items, // Items from the form
        };

        // Insert the sales records for each item in the form
        return axios.post(
          `${API_BASE_URL}/salesrecord/insert`,
          salesRecordData
        );
      })
      .then(() => {
        // After inserting the records, fetch the sales data and reset the form
      })
      .catch((error) => {
        console.error("Error adding sale or inserting sales record:", error);
      });
  };

  const setCouponInForm = (coupon) => {
    setForm({ ...form, coupon: coupon });
  };

  const handleSaveSales = () => {
    if (!validateForm()) {
      return;
    }
    setIsEditVisible(false);
    setIsPreviewVisible(true); // Show the Preview grid
  };

  const handleConfirmClick = () => {
    handleConfirm();
    setIsPreviewVisible(false);
    setIsConfirmVisible(true); // Show the Confirm grid when Confirm button is clicked
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {isEditVisible && (
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <DeliveryDetails
              form={form}
              handleChange={handleChange}
              isEditMode={false}
              errors={errors}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography
              sx={{
                fontFamily: FONT_FAMILY,
                fontSize: "20px",
                fontWeight: 500,
              }}
            >
              Order Summary
            </Typography>
            <OrderSummary
              setItems={setItemsInForm}
              setCartPrice={setCartPriceInForm}
              cartItems={storedCart}
              action="Checkout"
              setCoupon={setCouponInForm}
              coupon={form.coupon}
              comboCart={comboCart}
              deliveryState={form.state}
              setAutoDiscountsInParent={setAutoDiscounts}
            />
            <CustomButton
              label="Order now"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleSaveSales}
            />
          </Grid>
        </Grid>
      )}
      {isPreviewVisible && (
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <DeliveryDetails
              form={form}
              handleChange={handleChange}
              isEditMode={true} // Set to true for edit mode, false for preview mode
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography
              sx={{
                fontFamily: FONT_FAMILY,
                fontSize: "20px",
                fontWeight: 500,
              }}
            >
              Preview Order Summary
            </Typography>
            <OrderSummary
              setItems={setItemsInForm}
              setCartPrice={setCartPriceInForm}
              cartItems={storedCart}
              action="Checkout"
              setCoupon={setCouponInForm}
              coupon={form.coupon}
              comboCart={comboCart}
              deliveryState={form.state}
            />

            <CustomButton
              label="Confirm"
              variant="contained"
              color="primary"
              size="large"
              onClick={handleConfirmClick}
            />
          </Grid>
        </Grid>
      )}
      {isConfirmVisible && (
        <Grid container spacing={3} id="CONFIRM">
          <Grid item xs={12}>
            <ConfirmationPage
              orderNo={"89"}
              onContinueShopping={handleContinueShopping}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
