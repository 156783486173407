import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import { API_BASE_URL, IMAGE_BASE_URL } from "../../config/constants";
import { FONT_FAMILY } from "../../config/themeConstants"; // Import FONT_FAMILY
import {
  Grid,
  Typography,
  Button,
  CircularProgress,
  Box,
  Tabs,
  Tab,
} from "@mui/material";
import { GlobalContext } from "./../../GlobalProvider";

const ComboOfferDetailsPage = () => {
  const { id } = useParams();
  const [combo, setCombo] = useState(null);
  const [itemsData, setItemsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0); // State for selected tab
  const navigate = useNavigate();
  const { globalVariable, updateGlobalVariable } = useContext(GlobalContext);

  useEffect(() => {
    const fetchComboDetails = async () => {
      try {
        const comboResponse = await axios.get(`${API_BASE_URL}/combo/${id}`);
        setCombo(comboResponse.data);
        const itemIds = comboResponse.data.items;
        const itemResponse = await axios.get(`${API_BASE_URL}/items`);
        const allItems = itemResponse.data;
        const matchedItems = itemIds
          .map((id) => allItems.find((item) => item.inventoryid === id))
          .filter(Boolean);
        setItemsData(matchedItems);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching combo or items.", error);
        setLoading(false);
      }
    };

    fetchComboDetails();
  }, [id]);

  const addToCart = () => {
    if (combo) {
      const storedCart = JSON.parse(localStorage.getItem("comboCart")) || {};
      storedCart[combo.id] = {
        comboId: combo.id,
        comboname: combo.comboname,
        price: Number(combo.price),
        items: itemsData.map((item) => ({
          inventoryid: item.inventoryid,
          label: item.label,
          image: item.image,
          price: item.price,
          quantity: 1,
        })),
      };
      localStorage.setItem("comboCart", JSON.stringify(storedCart));
    }
  };

  const buyNowItem = () => {
    if (combo) {
      const storedCart = {
        [combo.id]: {
          comboId: combo.id,
          comboname: combo.comboname,
          price: Number(combo.price),
          items: itemsData.map((item) => ({
            inventoryid: item.inventoryid,
            label: item.label,
            image: item.image,
            price: item.price,
            quantity: 1,
          })),
        },
      };
      updateGlobalVariable("comboCart", storedCart);
      updateGlobalVariable("cartItems", {});
      navigate(`/checkout?buynow=true`);
    }
  };

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  // Calculate total price of individual items
  const totalOriginalPrice = itemsData.reduce(
    (sum, item) => sum + Number(item.price),
    0
  );

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      {combo ? (
        <>
          <Grid container spacing={1}>
            {/* Tab Section */}
            <Grid item xs={12} md={6}>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                variant="fullWidth"
                sx={{
                  backgroundColor: "rgba(200, 200, 200, 0.2)", // Light gray with transparency
                  "& .MuiTab-root": {
                    color: "rgba(0, 0, 0, 0.7)", // Darker text for visibility
                    fontWeight: "bold",
                    fontFamily: FONT_FAMILY,
                  },
                  "& .Mui-selected": {
                    backgroundColor: "rgba(180, 180, 180, 0.3)", // Slightly darker transparent gray for selected tab
                    color: "black",
                  },
                }}
              >
                {itemsData.map((item, index) => (
                  <Tab key={item.inventoryid} label={item.label} />
                ))}
              </Tabs>

              {itemsData.map((item, index) => (
                <Box
                  key={item.inventoryid}
                  role="tabpanel"
                  hidden={selectedTab !== index}
                  sx={{ textAlign: "center", mt: "0px" }}
                >
                  {selectedTab === index && (
                    <>
                      <Box
                        component="img"
                        src={IMAGE_BASE_URL + item.image}
                        alt={item.label}
                        sx={{
                          width: { xs: "100%", md: "100%" },
                          height: { xs: "300px", md: "600px" },
                          borderRadius: "8px"
                        }}
                      />
                    </>
                  )}
                </Box>
              ))}
            </Grid>

            {/* Price Section */}
            <Grid item xs={12} md={6}>

              <Box sx={{ mt: "0px"}}>
              <Typography sx={{ mb: "10px",  fontFamily: FONT_FAMILY,fontSize: "18px" }}>
            {combo.comboname}
          </Typography>
              {itemsData.map((item, index) => (
             <Typography sx={{ fontFamily: FONT_FAMILY }}>
             {item.label}: ₹{Number(item.price).toFixed(2)}
           </Typography>
                ))}
                <Typography
                  sx={{
                    color: "gray",
                    textDecoration: "line-through",
                    mb: "2px",
                    fontFamily: FONT_FAMILY,
                  }}
                >
                  Original Price: ₹{totalOriginalPrice.toFixed(2)}
                </Typography>
                <Typography variant="h5" sx={{ color: "teal", fontFamily: FONT_FAMILY }}>
                  Combo Price: ₹{Number(combo.price).toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: "10px",
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  gap: 2,
                  justifyContent: { xs: "center", md: "flex-start" },
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  onClick={buyNowItem}
                  sx={{ width: { xs: "100%", md: "auto" }, fontFamily: FONT_FAMILY }}
                >
                  Buy Now
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  fullWidth
                  onClick={addToCart}
                  sx={{ width: { xs: "100%", md: "auto" }, fontFamily: FONT_FAMILY }}
                >
                  Add to Cart
                </Button>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="h6" sx={{ fontFamily: FONT_FAMILY }}>
          Combo not found.
        </Typography>
      )}
    </Box>
  );
};

export default ComboOfferDetailsPage;
