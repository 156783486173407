import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_BASE_URL } from "../../config/constants";
import SeasonGreetings from "./SeasonGreetings";
import CategoryDisplay from "./CategoryDisplay";

const WelcomePage = () => {
  const categories = [
    {
      title: "Necklace",
      image: "/images/illolam_399.jpg",
      href: "/list/Necklace",
    },
    {
      title: "Haarams",
      image: "/images/illolam_433.jpg",
      href: "/list/Haarams",
    },
    { title: "Bangles", image: "/images/illolam_237.jpeg", href: "/list/BB" },
    {
      title: "Earrings",
      image: "/images/illolam_399.jpg",
      href: "/list/Earrings",
    },
   /* {
      title: "AD Stone",
      image: "/images/illolam_218.jpg",
      href: "/list/Adstone",
    },
    { title: "All Items", image: "/images/illolam_233.jpg", href: "/list/All" },*/
  ];
  const specials = [
    {
      title: "Combo Offers",
      image: "/images/illolam_433.jpg",
      href: "/combooffer",
    },
    {
      title: "Clearance Sale",
      image: "/images/illolam_433.jpg",
      href: "/list/Necklace",
    },
   /* {
      title: "Onam Sale",
      image: "/images/illolam_218.jpg",
      href: "/list/Necklace",
    },*/
  ];

  return (
    <>
      {/* Welcome Message Box - Full Width */}
      <Box>
        <SeasonGreetings />
        {/* Categories Section */}
        <Box sx={{ mt: 2 }}>
          <CategoryDisplay categories={categories} title={"Categories"} />
        </Box>
        {/* Specials Section */}
        <Box sx={{  mt: { xs: 3, md: 4 } }}>
          <CategoryDisplay categories={specials} title={"Specials"} />
        </Box>
      </Box>
    </>
  );
};

export default WelcomePage;
