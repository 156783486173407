import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, IconButton, Box, Grid } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { API_BASE_URL} from '../../config/constants';

const CategoryManagement = () => {
  const [categories, setCategories] = useState([]);
  const [form, setForm] = useState({ id: null, label: '', value: '' });
  const [isEditing, setIsEditing] = useState(false);

  // Fetch categories from the database when component mounts
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/categories`);  // Replace with your API endpoint
      setCategories(response.data);
    } catch (error) {
      console.error('Failed to fetch categories:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async () => {
    if (isEditing) {
      // Update existing category
      await axios.put(`${API_BASE_URL}/categories/${form.id}`, form);  // Replace with your API endpoint
    } else {
      // Create new category
      await axios.post(`${API_BASE_URL}/categories`, form);  // Replace with your API endpoint
    }
    fetchCategories();  // Refresh the category list
    resetForm();
  };

  const handleEdit = (category) => {
    setForm(category);
    setIsEditing(true);
  };

  const handleDelete = async (categoryId) => {
    await axios.delete(`${API_BASE_URL}/categories/${categoryId}`);  // Replace with your API endpoint
    fetchCategories();  // Refresh the category list
  };

  const resetForm = () => {
    setForm({ id: null, label: '', value: '' });
    setIsEditing(false);
  };

  return (
    <Box sx={{ marginTop: 4 }}>
      <h1>Web Category Management</h1>

      {/* Inline Entry Form */}
      <Box sx={{ marginBottom: 4 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={5}>
            <TextField
              fullWidth
              label="Web Category Name"
              name="label"
              value={form.label}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              fullWidth
              label="Category Value"
              name="value"
              value={form.value}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
            >
              {isEditing ? 'Update' : 'Add'}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Categories Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Category Name</TableCell>
              <TableCell>Category Value</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category) => (
              <TableRow key={category.id}>
                <TableCell>{category.label}</TableCell>
                <TableCell>{category.value}</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleEdit(category)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="secondary" onClick={() => handleDelete(category.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default CategoryManagement;
