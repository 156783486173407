import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import ShoppingBagTwoToneIcon from "@mui/icons-material/ShoppingBagTwoTone";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { IMAGE_BASE_URL } from "../../config/constants";
import { useImageCache } from "../../App"; // Import the custom hook

const SalesCard = ({
  image,
  title,
  addToCart,
  viewProductDetails,
  discount,
  sellingprice,
  itemleft,
  originalprice,
}) => {
  // Safely convert sellingprice and originalprice to numbers and handle NaN cases
  const formattedSellingPrice = Number(sellingprice)
    ? Number(sellingprice).toFixed(2)
    : "0.00";
  const formattedOriginalPrice = Number(discount)
    ? (Number(sellingprice) * (1 + Number(discount) / 100)).toFixed(2)
    : Number(sellingprice).toFixed(2);

  const imageCache = useImageCache();
  const cachedImage = imageCache.get(image) || IMAGE_BASE_URL + image;
  const [loaded, setLoaded] = useState(false);
  return (
    <Box sx={{ maxWidth: 350, position: "relative" }}>
      {/* Card containing the image */}
      <Card sx={{ position: "relative" }}>
        <Box sx={{ position: "relative", overflow: "hidden" }}>
          <CardMedia
            component="img"
            sx={{
              height: { xs: 250, md: 370 }, // Adjust height for screen sizes
              width: "100%", // Full width
              objectFit: "cover", // Cover the container
              transition:
                "opacity 0.4s ease-in-out, transform 0.3s ease-in-out", // Smooth fade-in and hover transition
              opacity: loaded ? 1 : 0, // Starts hidden
              transform: loaded ? "scale(1)" : "scale(1.05)", // Slight scale on load
              cursor: "pointer", // Add pointer cursor
              "&:hover": {
                transform: "scale(1.05)", // Slight zoom on hover
              },
            }}
            image={cachedImage} // Image source
            title={title} // Image title
            onClick={viewProductDetails} // Action on click
            onLoad={() => setLoaded(true)} // Set loaded to true when the image loads
          />
          {/* Conditionally render the discount label */}

          <Box
            sx={{
              position: "absolute",
              bottom: 8,
              left: 8,
              backgroundColor: "black",
              color: "white",
              borderRadius: "12px",
              padding: "4px 8px",
              fontSize: { xs: "10px", md: "12px" },
            }}
          >
            {discount > 0 && (
              <Typography
                variant="caption"
                sx={{
                  color: "white",
                  marginRight: { xs: "10px", md: "15px" },
                  fontSize: { xs: "11px", md: "11px" },
                }}
              >
                {discount}% OFF
              </Typography>
            )}
            <Typography
              variant="caption"
              sx={{
                color: "white",
                fontSize: { xs: "11px", md: "11px" },
              }}
            >
              {itemleft} in stock {/* Safely formatted original price */}
            </Typography>
          </Box>
        </Box>

        {/* Texts with Dividers */}
        <Box sx={{ margin: "8px 0px 8px 8px" }}>
          <Typography
            variant="caption"
            onClick={viewProductDetails} // Trigger viewProductDetails on click
            sx={{
              color: "black",
              cursor: "pointer", // Make it look clickable
              "&:hover": { textDecoration: "underline" }, // Hover effect
              fontSize: { xs: "13px", md: "13px" },
            }}
          >
            {title}
          </Typography>
          <br />

          {/* Display original price with strikethrough and selling price next to it */}
          {discount > 0 && (
            <Typography
              variant="caption"
              sx={{
                textDecoration: "line-through",
                color: "gray",
                fontSize: { xs: "11px", md: "12px" },
              }}
            >
              ₹ {formattedOriginalPrice} {/* Safely formatted original price */}
            </Typography>
          )}
          <Typography
            variant="caption"
            sx={{ color: "black", ml: 1, fontSize: { xs: "12px", md: "14px" } }}
          >
            ₹ {formattedSellingPrice} {/* Safely formatted selling price */}
          </Typography>
        </Box>
      </Card>

      {/* Cart icon button in the bottom-right corner of the parent Box */}
      <IconButton
        sx={{
          position: "absolute",
          bottom: 15,
          right: 0,
          backgroundColor: "white",
          color: "teal",
          "&:hover": {
            backgroundColor: "lightgray", // Add hover effect
          },
        }}
        onClick={addToCart}
      >
        <AddShoppingCartIcon />
      </IconButton>
    </Box>
  );
};

export default SalesCard;
